import React, {Fragment, useEffect, useState} from "react";
import Configuration from "../../Configuration";
import "./VideoStreamViewer.css";
import AuthenticationService from "../../Service/AuthenticationService";
import AWebRTCWrapper from "./AWebRTCWrapper/AWebRTCWrapper";
import WebrtcStateService from "../../Service/WebrtcStateService";
import WebrtcAPIService from "../../Service/WebrtcAPIService";
import DebugService from "../../Service/DebugService";

const VideoStreamViewer = (props) => {

  const TAG = ">> VideoStreamViewer::";
  const [state, setState] = useState({id: null, name: null});

  let snippet = <Fragment/>;

  const updateState = (event) => {
    let newState = event.detail;
    if (!!newState) {

      setState({
        ...newState
      });
    }
  }
  const handleRecognizerFocusedEvent = async (event) => {
    let payload = event.detail;
    let id = payload.id;
    let name = payload.name;
    console.debug(`${TAG}Handling recognizer focus event`, id)
    if (!!id) {

      let iceServersResponse = await WebrtcAPIService.fetchIceServers();

      let iceServerList = iceServersResponse.iceServerList;

      let viewerAddress = await WebrtcAPIService.fetchViewerBroadcastAddress(id);

      console.debug(TAG, "Dispatching recognizer update state event, Ice Servers are", iceServerList, " and viewer address is ", viewerAddress);

      WebrtcStateService.dispatchRecognizerUpdateStateEvent({
        id: id,
        iceServerList: iceServerList,
        viewerAddress: viewerAddress.address,
        name: name
      });

    } else {
      setState({id: null});
    }
  }

  useEffect(() => {
    DebugService.showDebugMessage(`${TAG} state id: ${state.id}`);
    if (shouldIncludeWebRTCCall()) {
      WebrtcStateService.startRecordingWebRTCVideoEvents();
    }
    WebrtcStateService.addRecognizerFocusEventListener(handleRecognizerFocusedEvent);
    WebrtcStateService.addRecognizerUpdateStateEventListener(updateState);


    return () => {
      WebrtcStateService.removeRecognizerFocusEventListener(handleRecognizerFocusedEvent);
      WebrtcStateService.removeRecognizerUpdateStateEventListener(updateState);
      WebrtcStateService.stopRecordingWebRTCVideoEvents();
    };
    //eslint-disable-next-line
  }, [state.id])

  const shouldIncludeWebRTCCall = () => {
    let isReadyForWebRTC = !!state.id &&
        !!state.viewerAddress &&
        !!AuthenticationService.keycloakState &&
        AuthenticationService.keycloakState.authenticated;

    return isReadyForWebRTC;
  }

  if (shouldIncludeWebRTCCall()) {
    snippet = <AWebRTCWrapper
      channel={state.viewerAddress}
      name={state.name}
      iceServerList={state.iceServerList}
      signalingWebsocketServerUrl={Configuration.signalingWebsocketServerUrl + "?" + AuthenticationService.getAccessTokenQueryParameter()}
    />
  }


  return (<div className={"VideoStreamViewer"}>
    {snippet}
  </div>);
}

export default VideoStreamViewer;
