import {NavLink} from "react-router-dom";
import AuthenticationService from "../../Service/AuthenticationService";
import "./Header.css";

const Header = () => {

    let keycloakState = AuthenticationService.keycloakState;


    let links = [<h1 key={"home"}><NavLink to={"/"} activeClassName={"Header-Active"}>Home</NavLink></h1>];

    if (!!keycloakState && keycloakState.authenticated) {
        links.push(<h1 key={"map"} ><NavLink to={"/map" } activeClassName={"Header-Active"}>Map</NavLink></h1>);
        links.push(<h1 key={"qrcode"}><NavLink to={"/qr-code"} activeClassName={"Header-Active"}>QR Code</NavLink></h1>);
    }

    let headerSnippet = <span className={"Header"}>
        {links}
        </span>;

    return (
        headerSnippet
    );
}

export default Header;
