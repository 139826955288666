import DashboardAPIService from "./DashboardAPIService";
import Configuration from "../Configuration";
import RecognizerService from "./RecognizerService";
import CardService from "./CardService";
import PassportCardService from "./PassportCardService";
import Cache from "timed-cache";

const DashboardStateService = {

    TAG: "DashboardStateService::",
    geolocationResolved: false,
    appResultsRetrieved: false,
    appLocationSetByMarker: false,
    dashboardIntervalTimeoutId: null,
    mockRecognizersIntervalCounter: 0,
    isForceOverrideMock: false,
    isForceOverrideNotMock: false,
    isPollingForRecognizers: false,
    recentDispatchedRecognizerResponsesExpiringCache: null,
    mapboxState : {latitude: 38.771112, longitude: -77.1415815, zoom: 16},
    dashboardResponseHistory : [],

    isMock: () => {
        let isMock = false;
        if (window && window.location) {
            isMock = window.location.hostname === "localhost";
        }
        if (DashboardStateService.isForceOverrideMock) {
            isMock = true;
        }
        if (DashboardStateService.isForceOverrideNotMock) {
            isMock = false;
        }

        return isMock;
    },
    dispatchMarkerLocation: (payload) => {
        const event = new CustomEvent('markerlocation', {
            detail: payload
        });

        document.dispatchEvent(event);
    },
    addGeolocationEventHandler: (callback) => {
        const locationEventHandlerOptions = {
            capture: true,
            once: true
        };
        document.addEventListener("geolocation", callback, locationEventHandlerOptions);
    },
    removeGeolocationEventHandler: (callback) => {
        const locationEventHandlerOptions = {
            capture: true,
            once: true
        };
        document.removeEventListener("geolocation", callback, locationEventHandlerOptions);
    },
    addMarkerLocationEventHandler: (callback) => {
        const locationEventHandlerOptions = {
            capture: true,
            once: true
        };
        document.addEventListener("markerlocation", callback, locationEventHandlerOptions);
    },

    removeMarkerLocationEventHandler: (callback) => {
        const locationEventHandlerOptions = {
            capture: true,
            once: true
        };
        document.removeEventListener("markerlocation", callback, locationEventHandlerOptions);
    },
    startPollingForRecognizers: () => {


        if (DashboardStateService.isPollingForRecognizers) {
            return;
        }
        DashboardStateService.isPollingForRecognizers = true;

        let repeatedlyGetRecognizers = () => {

            console.debug(DashboardStateService.TAG + ">>> RepeatedlyGetRecognizers every " + Configuration.dashboardFetchIntervalInMilliseconds + " milliseconds");

            try {
                DashboardAPIService.getRecognizers()
                    .then(DashboardStateService.handleRecognizersResponse)
                    .catch(DashboardStateService.dispatchRecognizersUpdatedFailedEventHandler);
            } catch (error) {
                console.error(DashboardStateService.TAG + "Error occurred", error);
            }

            console.debug(DashboardStateService.TAG + "Setting timeout");

            DashboardAPIService.dashboardIntervalTimeoutId = setTimeout(repeatedlyGetRecognizers, Configuration.dashboardFetchIntervalInMilliseconds);

            console.debug(DashboardStateService.TAG + "<<<repeatedlyGetRecognizers");

        }


        repeatedlyGetRecognizers();
    },
    stopPollingForRecognizers: () => {

        if (DashboardStateService.isPollingForRecognizers) {

            if (!!DashboardStateService.dashboardIntervalTimeoutId) {
                clearTimeout(DashboardStateService.dashboardIntervalTimeoutId);
            }
            DashboardStateService.isPollingForRecognizers = false;
        }


        if (!!DashboardStateService.appResultsRetrieved) {
            console.debug(DashboardStateService.TAG + ">>useEffect >>cleanup:: Clearing flag for app results retrieved");
            DashboardStateService.appResultsRetrieved = false;
        }
    },
    handleRecognizersResponse: (markers) => {


        let recognizers = [];

        if (markers && markers.length) {

            console.debug(DashboardStateService.TAG + "Count of recognizer markers", markers.length);
            const uniqueRecognizers = RecognizerService.getUniqueRecognizers(markers); //get list of unique recognizers
            console.debug(DashboardStateService.TAG + "Count of unique recognizer markers", uniqueRecognizers.length);
            const uniqueRecognizersAndMostRecentlyUpdatedTypes = RecognizerService.getLatestTypes(markers, uniqueRecognizers); // add most recently updated types

            console.debug(DashboardStateService.TAG + "List of unique recognizer markers mapped to their most recently updated types", uniqueRecognizersAndMostRecentlyUpdatedTypes);

            const uniqueRecognizersAndDataForMostRecentlyUpdatedTypes = RecognizerService.getAggregatedDataForLatestTypes(markers, uniqueRecognizersAndMostRecentlyUpdatedTypes); // aggregated data

            console.debug(DashboardStateService.TAG + "List of unique recognizer markers mapped to aggregated data for their most recently updated types", uniqueRecognizersAndDataForMostRecentlyUpdatedTypes);

            for (let index in uniqueRecognizersAndDataForMostRecentlyUpdatedTypes) {

                let recognizerData = uniqueRecognizersAndDataForMostRecentlyUpdatedTypes[index];
                console.debug(`${DashboardStateService.TAG} Processing ${JSON.stringify(recognizerData)}`);

                let add = true;

                if (!!recognizerData && recognizerData.length > 0 && CardService.isPassportCard(recognizerData[0].type)) {
                    add = PassportCardService.isPassportCardDataValid(recognizerData);
                    console.debug(DashboardStateService.TAG + "Type is passport type and data validity is: ", add);
                }

                if (add) {
                    recognizers.push(recognizerData);
                } else {
                    console.debug(DashboardStateService.TAG + "Skipping adding recognizer data at index ", index, ": ", recognizerData);
                }
            }

        }

        if (recognizers && recognizers.length) {
            console.debug(`${DashboardStateService.TAG} Dispatching recognizer-updated event with ${JSON.stringify(recognizers)}`)
            DashboardStateService.recognizerResultsRetrieved = true;

            recognizers = DashboardStateService.refillWithRecentlyDispatchedRecognizerResponses(recognizers);
        }
        DashboardStateService.dispatchRecognizersUpdatedEventHandler(recognizers);
    },
    dispatchRecognizersUpdatedEventHandler: (recognizers) => {
        let event = new CustomEvent("recognizers-updated", {
            detail: recognizers
        });
        document.dispatchEvent(event);
    },
    dispatchRecognizersUpdatedFailedEventHandler: () => {
        let event = new CustomEvent("recognizers-updated", {
            detail: false
        });
        document.dispatchEvent(event);
    },
    addRecognizersUpdatedEventHandler: (callback) => {
        document.addEventListener("recognizers-updated", callback)
    },
    removeRecognizersUpdatedEventHandler: (callback) => {
        document.removeEventListener("recognizers-updated", callback)
    },
    reset: () => {

        DashboardStateService.geolocationResolved = false;
        DashboardStateService.appResultsRetrieved = false;
        DashboardStateService.appLocationSetByMarker = false;
        DashboardStateService.dashboardIntervalTimeoutId = null;
        DashboardStateService.mockRecognizersIntervalCounter = 0;
        DashboardStateService.isForceOverrideMock = false;
        DashboardStateService.isPollingForRecognizers = false;
        DashboardStateService.recentDispatchedRecognizerResponsesExpiringCache = null;
    },
    refillWithRecentlyDispatchedRecognizerResponses : (recognizers) => {
        if (!DashboardStateService.recentDispatchedRecognizerResponsesExpiringCache) {
            DashboardStateService.recentDispatchedRecognizerResponsesExpiringCache = new Cache();
            DashboardStateService.recentDispatchedRecognizerResponsesExpiringCache.defaultTtl = 30 * 1000;
        }

        let cache = DashboardStateService.recentDispatchedRecognizerResponsesExpiringCache;

        let recentlyDispatchedRecognizerResponsesCacheKeys = Object.keys(cache);

        let recognizersToBeRemoved = [];
        let recognizersToBeAdded = [];

        let originalCurrentRecognizers = JSON.parse(JSON.stringify(recognizers));

        console.debug(`${DashboardStateService.TAG} iterating through recently dispatched recognizer responses cache keys`, recentlyDispatchedRecognizerResponsesCacheKeys);
        for (let responseIndex = 0; responseIndex < recentlyDispatchedRecognizerResponsesCacheKeys.length; responseIndex++) {

            let recentlyDispatchedRecognizerResponse = cache.get(recentlyDispatchedRecognizerResponsesCacheKeys[responseIndex]);

            if (typeof recentlyDispatchedRecognizerResponse === "undefined") {
                break;
            }
            console.debug(`${DashboardStateService.TAG} \t iterating the recent response recognizer data, count is`, responseIndex);
            console.debug(`${DashboardStateService.TAG} \t iterating the recent response recognizer data, count is ${recentlyDispatchedRecognizerResponse.length}`);
            for (let recognizerIndex = 0; recognizerIndex < recentlyDispatchedRecognizerResponse.length; recognizerIndex++) {

                let recognizerData = recentlyDispatchedRecognizerResponse[recognizerIndex];

                if (recognizerData.type === 0) {
                    continue;
                }

                let recognizerIdFoundInCurrentRecognizers = false;

                console.debug(`${DashboardStateService.TAG} \t\t checking the recognizer data for id ${recognizerData.id} and type ${recognizerData.type} against current recognizer ids and types`);
                for (let currentRecognizersIndex = 0; currentRecognizersIndex < recognizers.length; currentRecognizersIndex++) {
                    let currentRecognizer = recognizers[currentRecognizersIndex];
                    if (currentRecognizer.id === recognizerData.id && currentRecognizer.type !== recognizerData.type) {

                        console.debug(`${DashboardStateService.TAG} \t\t found match for id ${recognizerData.id} and type ${recognizerData.type} != ${currentRecognizer.type()}`);
                        if (currentRecognizer.type === 0) {

                            recognizersToBeRemoved.push(currentRecognizer);
                            recognizerIdFoundInCurrentRecognizers = false;
                            console.debug(`${DashboardStateService.TAG} \t\t\t marked for removal!`);
                            break;
                        }

                        recognizerIdFoundInCurrentRecognizers = true;
                    }
                }

                if (!recognizerIdFoundInCurrentRecognizers) {
                    recognizersToBeAdded.push(recognizerData);
                }
            }
        }

        for (let recognizerIndex = recognizers.length - 1; recognizerIndex >= 0; recognizerIndex--) {
            let recognizerData = recognizers[recognizerIndex];
            for (let index = 0; index < recognizersToBeRemoved.length; index++) {
                let recognizerToBeRemoved = recognizersToBeRemoved[index];
                if (recognizerData.id === recognizerToBeRemoved.id) {
                    recognizers.splice(recognizerIndex);
                }
            }
        }

        for (let index = 0; index < recognizersToBeAdded.length; index++) {
            let recognizerToBeAdded = recognizersToBeAdded[index];
            recognizers.push(recognizerToBeAdded);
        }

        console.debug(`${DashboardStateService.TAG} adding cache ${originalCurrentRecognizers.length} entries`);
        cache.put("cache-entry-" + new Date().getTime() + "", originalCurrentRecognizers);

        DashboardStateService.recentDispatchedRecognizerResponsesExpiringCache = cache;

        return recognizers;
    }
}


export default DashboardStateService;
