import React from "react";
import 'mapbox-gl/dist/mapbox-gl.css';
import './DashboardMap.css';
import MapboxMapContainer from "../MapboxMapContainer/MapboxMapContainer";
import VideoStreamViewer from "../VideoStreamViewer/VideoStreamViewer";
import DashboardMapHeader from "./Header/DashboardMapHeader";
import ChatContainer from "../ChatContainer/ChatContainer";
import DashboardStateService from "../../Service/DashboardStateService";

const DashboardMap = (props) => {


    DashboardStateService.reset();


    console.debug("DashboardMap")

    return (
        <div className={`DashboardMap`}>
            <div className={"DashboardMap-Header"}>
                <DashboardMapHeader/>
            </div>
            <div className={"DashboardMap-Panels"}>
                <div className={"DashboardMap-LeftPanel"}>
                    <div className={"DashboardMap-LeftPanel-Top"} role={"application"}>
                        <MapboxMapContainer/>
                    </div>
                </div>
                <div className={"DashboardMap-RightPanel"}>
                    <div className={"DashboardMap-RightPanel-Top"} title={"Video Stream"}>
                        <VideoStreamViewer/>
                    </div>
                    <div className={"DashboardMap-RightPanel-Bottom"} title={"Chat"}>
                        <ChatContainer/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DashboardMap;
