import React, {useRef} from "react";

import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import {InteractiveMap, NavigationControl} from 'react-map-gl';
import './MapboxMapContainer.css';

import Geocoder from "react-map-gl-geocoder/src";
import RecognizersContainer from "./Recognizers/RecognizersContainer";
import DashboardStateService from "../../Service/DashboardStateService";
import Configuration from "../../Configuration";

const MapboxMapContainer = (props) => {

    const TAG = ">> MapboxMapContainer::";

    const [state, setState] = React.useState({latitude: 38.771112, longitude: -77.1415815, zoom: 16});

    const navControlStyle = {
        right: 10,
        bottom: 25
    };


    let mapRef = useRef();

    React.useEffect(() => {

        let handleGeolocationEvent = (event) => {
            if (!!DashboardStateService.geolocationResolved) {
                return;
            }

            DashboardStateService.geolocationResolved = true;


            if (!DashboardStateService.appResultsRetrieved || !DashboardStateService.appLocationSetByMarker) {
                setState({
                    ...state,
                    latitude: event.detail.latitude,
                    longitude: event.detail.longitude
                });
            }

            event.preventDefault();
        };

        let handleMarkerLocationEvent = (event) => {
            if (!!DashboardStateService.appLocationSetByMarker) {
                return;
            }

            DashboardStateService.appLocationSetByMarker = true;

            setState({
                ...state,
                latitude: event.detail.latitude,
                longitude: event.detail.longitude,
                appLocationSetByMarker: true
            });


            event.preventDefault();
        };

        DashboardStateService.addGeolocationEventHandler(handleGeolocationEvent);
        DashboardStateService.addMarkerLocationEventHandler(handleMarkerLocationEvent);

        return () => {

            DashboardStateService.removeGeolocationEventHandler(handleGeolocationEvent);
            DashboardStateService.removeMarkerLocationEventHandler(handleMarkerLocationEvent);

            // DashboardStateService.appLocationSetByMarker = false;
            DashboardStateService.geolocationResolved = false;

        };
        //eslint-disable-next-line
    }, []);

    const handleViewportChange = (newViewport) => {
        setState(newViewport)
    };

    const handleGeocoderViewportChange = (newViewport) => {
        const geocoderDefaultOverrides = {transitionDuration: 1000}

        return handleViewportChange({
            ...newViewport,
            ...geocoderDefaultOverrides
        });
    }

    const viewport = {
        latitude: state.latitude,
        longitude: state.longitude,
        zoom: state.zoom,
        bearing: 0,
        pitch: 0
    };

    console.debug(TAG, "Rendering mapbox map. Viewport: ", viewport, "State: ", state, "Props:", props);
    return (
        <React.Fragment>

            <InteractiveMap
                {...viewport}
                ref={mapRef}
                onViewportChange={handleViewportChange}
                width={"100%"}
                height={"100%"}
                mapStyle={Configuration.mapboxStyle}
                mapboxApiAccessToken={Configuration.mapboxToken}

            >
                <RecognizersContainer/>
                <NavigationControl
                    showZoom={true}
                    style={navControlStyle}
                    onViewportChange={handleViewportChange}
                ></NavigationControl>
                <Geocoder
                    mapRef={mapRef}
                    mapboxApiAccessToken={Configuration.mapboxToken}
                    onViewportChange={handleGeocoderViewportChange}
                    position={"top-right"}
                    marker={false}
                    enableEventLogging={false}
                    clearAndBlurOnEsc={false}
                    clearOnBlur={false}
                ></Geocoder>
            </InteractiveMap>
        </React.Fragment>
    );
}


export default MapboxMapContainer;
