import React from "react";
import PersonCardService from "../../../../Service/PersonCardService";
import CardService from "../../../../Service/CardService";
import "./PersonCard.css";


const PersonCard = (props) => {

    const cleanup = () => {
    }

    const setup = () => {

        return cleanup;
    }

    React.useEffect(setup);

    console.debug("Rendering passport card", props.data);

    let recognizerName = CardService.getRecognizerName(props.data),
        liveImage = PersonCardService.getPersonLiveImage(props.data),
        sourceImage = PersonCardService.getPersonSourceImage(props.data),
        sourceAndDate = PersonCardService.getPersonSourceAndDate(props.data),
        confidence = PersonCardService.getPersonConfidence(props.data),
        age = PersonCardService.getPersonAge(props.data),
        name = PersonCardService.getPersonName(props.data),
        gender = PersonCardService.getPersonGender(props.data),
        lastSeenDate = PersonCardService.getPersonLastSeenDate(props.data),
        personDerogatory = PersonCardService.getPersonIsDerogatory(props.data),
        personDisposition = PersonCardService.getPersonDisposition(props.data),
        personDispositionCss = personDerogatory ? "PersonCard-Disposition-Derogatory" : "PersonCard-Disposition";

    let middleColumn = !!sourceImage ? (<div className={"PersonCard-Middle"}>
            <img alt={"On-file person face"} className={"PersonCard-SourceImage"} src={sourceImage}></img>
            <div className={"PersonCard-SourceAndDate"}>{sourceAndDate}</div>
        </div>
    ) : (<React.Fragment></React.Fragment>);
    let rightColumn = (<React.Fragment>
        <div className={"PersonCard-RightDataContainer"}>
            <div className={"PersonCard-RightDataHeadersContainer"}>
                <div>Name</div>
                <div>Age / Gender</div>
                <div>Last Seen</div>
                <div>Confidence</div>
            </div>
            <div className={"PersonCard-RightDataValuesContainer"}>
                <div className={"PersonCard-NameValue"}>{name}</div>
                <div className={"PersonCard-AgeGenderValue"}>{age} / {gender}</div>
                <div className={"PersonCard-LastSeenValue"}>{lastSeenDate}</div>
                <div className={"PersonCard-ConfidenceValue"}>{confidence}</div>
            </div>
        </div>
        <div className={personDispositionCss}>{personDisposition}</div>
        <div className={"PersonCard-RecognizerName"}>
            <div>{recognizerName}</div>
        </div>
    </React.Fragment>);

    if (!sourceImage) {
        rightColumn = (
            <React.Fragment>
                <div className={"PersonCard-RightDataContainer-NoMatch"}>No Match</div>
                <div className={"PersonCard-RecognizerName"}>
                    <div>{recognizerName}</div>
                </div>
            </React.Fragment>
        )
    }


    return <div className={"PersonCard-Container"}>
        <div className="PersonCard-Left">
            <img alt={"Live person face"} className={"PersonCard-LiveImage"} src={liveImage}></img>
            <div className={"PersonCard-LiveImageCaption"}>
                <div>Live Photo</div>
            </div>
        </div>
        {middleColumn}
        <div className="PersonCard-Right">
            {rightColumn}
        </div>
    </div>;
};

export default PersonCard;
