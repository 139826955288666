import "./DashboardMapHeader.css";
import AuthenticationService from "../../../Service/AuthenticationService";
import {Fragment} from "react";
const DashboardMapHeader = (props) => {


  let keycloakState = AuthenticationService.keycloakState;

  let usernameSnippet = <Fragment />;

  if (!!keycloakState) {

    if (keycloakState.authenticated) {
      let lastName = keycloakState.tokenParsed.family_name;
      let firstName = keycloakState.tokenParsed.given_name;

      let lastNameSnippet = <Fragment />;
      let firstNameSnippet = <Fragment />;

      if (lastName && lastName.trim().length > 0) {
        lastName = lastName.trim() + ", ";
        lastNameSnippet = <span data-testid={"DashboardMapHeader-LastName"}>{lastName}</span>;
      }

      if (firstName && firstName.trim().length) {
        firstName  = firstName.trim();
        firstNameSnippet = <span data-testid={"DashboardMapHeader-FirstName"}>{firstName}</span>;
      }

      usernameSnippet =
        <div className={"DashboardMapHeader-Name"}>
          {lastNameSnippet}
          {firstNameSnippet}

        </div>;
    }
  }



  return <div className={"DashboardMapHeader"} role={"heading"} aria-level={1}>
    <div className={"DashboardMapHeader-ApplicationName"}>
    CosentiosAR
    </div>
    {usernameSnippet}
  </div>;
}

export default DashboardMapHeader;
