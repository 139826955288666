import React, {Fragment} from "react";
import PassportCardService from "../../../../Service/PassportCardService";
import CardService from "../../../../Service/CardService";
import "./PassportCard.css";


const PassportCard = (props) => {

    const cleanup = () => {
    }

    const setup = () => {

        return cleanup;
    }

    React.useEffect(setup);

    console.debug("Rendering passport card", props.data);

    let recognizerName = CardService.getRecognizerName(props.data),
        passportLiveFace = PassportCardService.getPassportLiveFace(props.data),
        passportDocumentMatch = PassportCardService.getPassportDocumentMatch(props.data),
        passportDocumentMatchCss = passportDocumentMatch === "NO MATCH" ? "PassportCard-DataField-Red" : "",
        passportLiveFaceCss = PassportCardService.getPassportLiveFaceCss(props.data),
        passportLiveColumnCss = PassportCardService.getPassportLiveColumnCss(props.data),
        passportDocumentExpiration = PassportCardService.getPassportDocumentExpiration(props.data),
        passportDocumentExpirationCss = PassportCardService.isPassportDocumentExpired(props.data) ? "PassportCard-DataField-Red" : "",
        passportDocumentNumber = PassportCardService.getPassportDocumentNumber(props.data),
        passportDocumentNumberCss = PassportCardService.getPassportDocumentNumberCss(props.data),
        passportDocumentGenderDob = PassportCardService.getPassportDocumentGenderDob(props.data),
        passportDocumentGenderDobCss = PassportCardService.getPassportDocumentGenderDobCss(props.data),
        passportDocumentName = PassportCardService.getPassportDocumentName(props.data),
        passportDocumentNameCss = PassportCardService.getPassportDocumentNameCss(props.data),
        passportDocumentFace = PassportCardService.getPassportDocumentFace(props.data);

    let passportLiveSnippet = <Fragment />;
    if (passportLiveFace) {
        passportLiveSnippet = <div className={passportLiveColumnCss}>
            <div>
                <b>Live</b>
            </div>
            <div className={passportLiveFaceCss}>
                <img alt={"Live face used for comparison to document face"} src={passportLiveFace}/>
            </div>
            <div data-testid={"PassportDocumentMatch"} className={passportDocumentMatchCss}>
                {passportDocumentMatch}
            </div>
        </div>
    }

    return <div className={"PassportCard-Container"}>
        <div className="PassportCard-Top">
            <div className="PassportCard-DocumentColumn">
                <div>
                    <div>
                        <b>Document</b>
                    </div>
                    <div>
                        <img alt={"Passport document face"} src={passportDocumentFace}/>
                    </div>
                    <div className={passportDocumentNameCss}>
                        {passportDocumentName}
                    </div>
                    <div className={passportDocumentGenderDobCss}>
                        {passportDocumentGenderDob}
                    </div>
                    <div data-testid={"PassportDocumentNumber"} className={passportDocumentNumberCss}>
                        {passportDocumentNumber}
                    </div>
                    <div className={passportDocumentExpirationCss}>
                        {passportDocumentExpiration}
                    </div>
                </div>
            </div>
            <div className="PassportCard-LiveColumn">
                {passportLiveSnippet}
            </div>
        </div>
        <div>
            <span>{recognizerName}</span>
        </div>
    </div>;
};

export default PassportCard;
