import AuthenticationService from "../../Service/AuthenticationService";

const Home = (props) => {

    let keycloakState = AuthenticationService.keycloakState;

    let homeSnippet = <div></div>;

    if (!!keycloakState) {

        if (keycloakState.authenticated) {

            let keycloakStateString = JSON.stringify(keycloakState, null, 2);
            homeSnippet = <div >
                <div>
                    <span data-testid={"dashboard-greeting-first-name"}>{keycloakState.tokenParsed.given_name}</span>
                    <span data-testid={"dashboard-greeting-last-name"}>{keycloakState.tokenParsed.family_name}</span>
                </div>
                <pre>{keycloakStateString}</pre>
            </div>;
        }
    }
    return (

        homeSnippet
    );
}

export default Home;
