import TYPES from "../Component/MapboxMapContainer/Recognizers/Types";
import CardService from "./CardService";
import PassportCardService from "./PassportCardService";

const PersonCardService = {
    findPersonDatum(data) {
        return CardService.findDatum(data, TYPES.FACE.id);
    },
    findPersonMatch(data) {
        let datum = this.findPersonDatum(data);
        return PassportCardService.getFirstFetchedContentWithProperty(datum, "faceMatch");
    },
    getPersonLiveImage(data) {
        let liveImage = "";

        let datum = this.findPersonDatum(data);
        if (!!datum) {
            liveImage = CardService.getImageUrlWithTimestamp(datum, "image1", datum.modificationTime);
        }
        return liveImage;
    },
    getPersonDisposition(data) {
        let disposition = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            disposition = faceMatch.disposition;
        }

        return disposition;
    },
    getPersonLastSeenDate(data) {
        let lastSeenDate = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            lastSeenDate = faceMatch.lastSeenDate;
        }

        return lastSeenDate;
    },
    isPersonHasDerogatory(data) {
        let isExpired = false;

        let expirationDate = this.getPersonLastSeenDate(data);
        if (!!expirationDate) {
            isExpired = Date.parse(expirationDate) < new Date().getTime();
        }

        return isExpired;
    },
    getPersonSourceAndDate(data) {
        let sourceAndDate = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {


            sourceAndDate = faceMatch.matchedImageSourceName + " " + faceMatch.matchedImageSourceDate
        }

        return sourceAndDate;
    },
    getPersonConfidence(data) {
        let confidenceScore = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            confidenceScore = faceMatch.confidence + "%";
        }

        return confidenceScore;
    },
    getPersonAge(data) {
        let age = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            age = faceMatch.age;
        }

        return age;
    },
    getPersonName(data) {
        let name = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            name = faceMatch.name;
        }

        return name;
    },
    getPersonSource(data) {
        let source = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            source = faceMatch.sourceName;
        }

        return source;
    },
    getPersonSourceDate(data) {
        let sourceDate = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            sourceDate = faceMatch.sourceDate;
        }

        return sourceDate;
    },
    getPersonIsDerogatory(data) {
        let derogatory = false;

        let personDatum = this.findPersonDatum(data);

        if (!!personDatum) {
            derogatory = personDatum.derogatory;
        }

        return derogatory;
    },
    getPersonSourceImage(data) {
        let sourceImage = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch && !!faceMatch.matchedImageBase64Data) {

            sourceImage = "data:image/jpg;base64," + faceMatch.matchedImageBase64Data;
        }

        return sourceImage;
    },
    getPersonGender(data) {
        let gender = "";

        let faceMatch = this.findPersonMatch(data);

        if (!!faceMatch) {
            gender = faceMatch.gender;
        }

        return gender;
    }
}

export default PersonCardService;
