import moment from "moment";
import TYPES from "../Component/MapboxMapContainer/Recognizers/Types";
import CardService from "./CardService";

const RecognizerService = {

  getUniqueRecognizers(data) {
    let recognizers = new Set();

    for (let index in data) {
      let datum = data[index];

      if (this.isNotValid(datum)) {
        continue;
      }

      recognizers.add(datum.id)
    }

    return Array.from(recognizers);
  },
  getLatestTypes(data, uniqueRecognizersIds) {

    let recognizersAndUniqueTypes = {};

    for (let recognizerIndex in uniqueRecognizersIds) {

      let recognizerId = uniqueRecognizersIds[recognizerIndex];
      recognizersAndUniqueTypes[recognizerId] = {
        latestModificationTime: -1,
        latestType: TYPES.NONE.id
      };

      let hasFoundPassportType = false;

      for (let index in data) {
        let datum = data[index];

        if (datum.id !== recognizerId) {
          continue;
        }

        if (this.isNotValid(datum)) {
          continue;
        }

        if (recognizersAndUniqueTypes[recognizerId].latestModificationTime === -1) {
          recognizersAndUniqueTypes[recognizerId].latestModificationTime = datum.modificationTime;
          recognizersAndUniqueTypes[recognizerId].latestType = CardService.getMainTypeFromDatum(datum);
          if (CardService.isPassportCard(recognizersAndUniqueTypes[recognizerId].latestType)) {
            hasFoundPassportType = true;
          }
        } else {
          let latestModificationTime = recognizersAndUniqueTypes[recognizerId].latestModificationTime;

          if (datum.modificationTime > latestModificationTime) {

            recognizersAndUniqueTypes[recognizerId].latestModificationTime = datum.modificationTime;
            recognizersAndUniqueTypes[recognizerId].latestType = CardService.getMainTypeFromDatum(datum);
            if (CardService.isPassportCard(recognizersAndUniqueTypes[recognizerId].latestType)) {
              hasFoundPassportType = true;
            }
          }
        }

        if (hasFoundPassportType && CardService.isPersonCard(recognizersAndUniqueTypes[recognizerId].latestType)) {
          recognizersAndUniqueTypes[recognizerId].latestType = TYPES.PASSPORT.id;
        }
      }
    }


    return recognizersAndUniqueTypes;
  },
  getAggregatedDataForLatestTypes(data, uniqueRecognizersAndMostRecentlyUpdatedTypes) {

    let recognizersAndAggregatedData = {};

    for (let id in uniqueRecognizersAndMostRecentlyUpdatedTypes) {
      let uniqueRecognizersAndMostRecentlyUpdatedType = uniqueRecognizersAndMostRecentlyUpdatedTypes[id];
      let aggregatedData = this.getAllDatumForIdAndType(id, uniqueRecognizersAndMostRecentlyUpdatedType.latestType, data);
      if (!!aggregatedData && aggregatedData.length > 0) {
        recognizersAndAggregatedData[id] = aggregatedData;
      }
    }

    return recognizersAndAggregatedData;
  }, getAllDatumForIdAndType(id, latestType, data) {

    let returnValue = [];

    for (let index in data) {
      let datum = data[index];

      if (!datum.id || datum.id !== id) {
        continue;
      }


      if (RecognizerService.isNotValid(datum)) {
        continue;
      }


      let isRelatedType = datum.fetchedContent.some(content => CardService.isRelatedType(latestType, content.type));

      if (!isRelatedType) {
        continue;
      }

      isRelatedType = CardService.isRelatedType(latestType, datum.type);

      if (!isRelatedType) {
        continue;
      }

      returnValue.push(datum);
    }


    return returnValue;
  },
  isExpired: function (datum) {

    let expired = true;
    if (!!datum.modificationTime) {
      let expiration = CardService.getExpiration(datum.type);
      let datumModificationTime = moment(datum.modificationTime)
      let expirationTime = moment().subtract(expiration.amount, expiration.unit);
      expired = datumModificationTime.isBefore(expirationTime);
    }

    return expired;

  },
  isNotValid: function (datum) {

    let isExpired = this.isExpired(datum);

    let isNotValid = isExpired ||
      !datum.lon || !datum.lat ||
      !datum.fetchedContent || !datum.fetchedContent.length ||
      datum.fetchedContent.length === 0 ||
      (datum.lon === -1 && datum.lat === -1);

    return isNotValid;
  }
};

export default RecognizerService;
