import moment from "moment";
import "./ChatBox.css";
import TextInput from "./TextInput/TextInput";
import {useEffect, useRef} from "react";
import WebrtcStateService from "../../../Service/WebrtcStateService";
import ThemeService from "../../../Service/ThemeService";

const ChatBox = (props) => {

    const messagesList = props.messages;
    let messagesSnippet = [];

    let threadedMessages = [];

    const TAG = ">> ChatBox::";
    console.debug(TAG, "In ChatBox")
    let chatboxRef = useRef();

    for (let messageIndex = 0; messageIndex < messagesList.length; messageIndex++) {

        let message = messagesList[messageIndex];
        let isSameAuthorAndMinuteAsLastMessage = false;

        if (threadedMessages.length > 0) {
            if (threadedMessages[threadedMessages.length - 1].author.id === message.author.id) {
                let timeSinceLastMessageInMinutes = moment(message.timestamp).diff(moment(threadedMessages[threadedMessages.length - 1].timestamp), 'minutes');
                if (timeSinceLastMessageInMinutes === 0) {
                    isSameAuthorAndMinuteAsLastMessage = true;
                }
            }
        }


        if (isSameAuthorAndMinuteAsLastMessage) {

            threadedMessages[threadedMessages.length - 1].messages.push(message);
        } else {
            threadedMessages.push(message);
            threadedMessages[threadedMessages.length - 1].messages = [message];
        }

    }

    for (let threadedMessageIndex = 0; threadedMessageIndex < threadedMessages.length; threadedMessageIndex++) {
        let threadedMessage = threadedMessages[threadedMessageIndex];

        let messagesTextSnippet = [];

        for (let messageIndex = 0; messageIndex < threadedMessage.messages.length; messageIndex++) {
            let message = threadedMessage.messages[messageIndex];
            let text = message.text;

            messagesTextSnippet.push(
                <div key={messageIndex} className={"ChatBox-Text"}>{text}</div>
            );
        }


        let displayName = threadedMessage.author.username;
        let displayTime = moment(threadedMessage.timestamp).format("h:mm A")
        if (threadedMessage.author.id === props.userId) {
            displayName = "You";
        }


        messagesSnippet.push(<div key={threadedMessageIndex} className={`ChatBox-Message`}>
            <div className={"ChatBox-NameTimeContainer"}>
                <div className={"ChatBox-DisplayName"}>{displayName}</div>
                <div className={"ChatBox-DisplayTime"}>{displayTime}</div>
            </div>
            <div className={"ChatBox-Text-Container"}>
                {messagesTextSnippet}
            </div>
        </div>);

    }

    const adjustLayout = () => {
        console.debug(TAG, "In Adjust Layout");

        if (!chatboxRef.current || !chatboxRef.current.offsetTop) {
            return;
        }

        let top = chatboxRef.current.offsetTop;
        let clientHeight = document.body.clientHeight
        let scrollBarWidth = 15; //(chatboxRef.current.scrollWidth - chatboxRef.current.clientWidth)
        let scrollBarHeight = 15; //(chatboxRef.current.scrollHeight - chatboxRef.current.clientHeight)

        let maxHeight = (clientHeight - top - scrollBarHeight) + "px";

        chatboxRef.current.style.height = maxHeight;
        chatboxRef.current.style.maxHeight = maxHeight;

        let left = chatboxRef.current.offsetLeft;
        let clientWidth = document.body.clientWidth

        let elementWidth = (clientWidth - left - scrollBarWidth);
        // if (elementWidth > WebrtcStateService.expectedVideoResolutionRatio) {
        //     elementWidth = WebrtcStateService.expectedVideoResolutionRatio;
        // }
        // let maxWidth = elementWidth + "px";
        // chatboxRef.current.style.width = maxWidth;
        // chatboxRef.current.style.maxWidth = maxWidth;


        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;


        let video = document.querySelector("video");

        if (video && elementWidth > 0) {


            const updateWidth = () => {
                let videoHeightWidthRatio = video.videoHeight / video.videoWidth;
                elementWidth *= 1;
                let elementHeight = elementWidth * videoHeightWidthRatio;
                console.debug("Loaded metadata vw:", video.videoWidth, "vh:", video.videoHeight, "ew:", elementWidth, "eh",elementHeight);
                let maxHeight = elementHeight + "px";

                if (elementHeight > 0) {

                    video.width = elementWidth;
                    video.height = elementHeight;
                    video.style.width = elementWidth;
                    video.style.maxWidth = elementWidth;
                    video.style.height = elementHeight;
                    video.style.maxHeight = elementHeight;

                    // DebugService.showDebugMessage("Video width: " + video.videoWidth);
                    // DebugService.showDebugMessage("Video height: " + video.videoHeight);

                    top = chatboxRef.current.offsetTop;
                    maxHeight = (clientHeight - top - scrollBarHeight) + "px";

                    chatboxRef.current.style.height = maxHeight;
                    chatboxRef.current.style.maxHeight = maxHeight;
                }
            }
            if (video.readyState === 0) {
                // metadata not loaded yet
                video.addEventListener( "loadedmetadata", updateWidth, false );
            } else if (video.readyState > 0) {
                updateWidth();
            }



        }

    }

    const handleResize = () => {
        adjustLayout();
    }

    const handleStalledVideo = () => {

        // #047
    }

    const webrtcVideoEventHandlers = {
        "added" : adjustLayout,
        "stalled" : handleStalledVideo,
        "play" : adjustLayout,
        "playing" : adjustLayout,
        "pause" : adjustLayout
    };

    const handleWebRTCVideoEvent = (eventName, event) => {
        let handler = webrtcVideoEventHandlers[eventName];
        if (handler) {
            handler(event);
        } else {
            adjustLayout();
        }
    }

    useEffect(() => {
        adjustLayout();


        ThemeService.addWindowResizeListener(handleResize);


        return () => {

            ThemeService.removeWindowResizeListener(handleResize);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        WebrtcStateService.addWebrtcVideoEventListeners(handleWebRTCVideoEvent);
       return () => {
           WebrtcStateService.removeWebrtcVideoEventListeners(handleWebRTCVideoEvent);
       }
    });
    return <div className={`ChatBox-Container`} ref={chatboxRef}>
        <div className={"ChatBox-Messages-Container"}>
            {messagesSnippet}
        </div>
        <div className={"ChatBox-TextInput-Container"}>
            <TextInput onSendMessage={props.onSendMessage} adjustLayout={adjustLayout}/>
        </div>
    </div>;
}

export default ChatBox;
