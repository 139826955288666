const Configuration = {
    dashboardEndpointUrl: "https://lab-api.cosentios.com/dashboard",
    username: "",
    password: "",
    nonceEndpointUrl: "https://lab-api.cosentios.com/token/nonce",
    keycloakUrl: "https://lab-api.cosentios.com/auth/",
    keycloakRealm: 'cosentiosar',
    keycloakClientId: 'cosentiosar-web',
    dashboardWebsocketTextEndpointUrl: "wss://lab-api.cosentios.com/websocket/dashboard/text/",
    cosentiosarApiKeycloakTokenEndpointUrl: "https://lab-api.cosentios.com/token",
    mapboxToken: 'pk.eyJ1Ijoic2FiZXQiLCJhIjoiY2lqcnoyMGNyMGd4dXVmbTVqYmdib3c1byJ9.FlqrASKb3NE0TbW4RTbcJg',
    mapboxStyle: "mapbox://styles/mapbox/cj44mfrt20f082snokim4ungi",
    dashboardFetchIntervalInMilliseconds: 3000,
    iceServerConfigUrl: "https://stream.lab-api.cosentios.com/iceconfig",
    broadcastViewAddressResolutionUrlPrefix: "https://stream.lab-api.cosentios.com/get-viewer-address-for-broadcast-address/",
    signalingWebsocketServerUrl: "wss://stream.lab-api.cosentios.com/broadcastapp",
    snippetHistoryExpirationDurationInMilliseconds : 60 * 1000,
    cardValidityExpirationIntervalAmount : 30,
    cardValidityExpirationIntervalUnit : "seconds",
    cardPopupExpirationCheckIntervalInMilliseconds : 300,
    cardPopupHistoryCheckIntervalInMilliseconds : 500,
}

export default Configuration;
