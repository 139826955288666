import Configuration from "../Configuration";
import AuthenticationService from "./AuthenticationService";

const WebrtcAPIService = {
    fetchIceServers : async () => {
        let response = await fetch(Configuration.iceServerConfigUrl + "?" + AuthenticationService.getAccessTokenQueryParameter());
        let iceServersResponse = await response.json();
        return iceServersResponse;
    },
    fetchViewerBroadcastAddress : async (id) => {
        let viewerAddressResponse = await fetch(Configuration.broadcastViewAddressResolutionUrlPrefix + id + "?" + AuthenticationService.getAccessTokenQueryParameter());

        let viewerAddress = await viewerAddressResponse.json();

        return viewerAddress;
    }


};
export default WebrtcAPIService;
