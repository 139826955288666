import TYPES from "../Component/MapboxMapContainer/Recognizers/Types";
import CardService from "./CardService";
import PassportCardService from "./PassportCardService";

const LicensePlateCardService = {
    findLicensePlateDatum(data) {
        return CardService.findDatum(data, TYPES.LICENSE_PLATE.id);
    },
    findLicensePlateMatch(data) {
        let datum = this.findLicensePlateDatum(data);
        return PassportCardService.getFirstFetchedContentWithProperty(datum, "licensePlateMatch");
    },
    getLicensePlateLiveImage(data) {
        let liveImage = "";

        let datum = this.findLicensePlateDatum(data);
        if (!!datum) {
            liveImage = CardService.getImageUrl(datum, "image1");
        }
        return liveImage;
    },
    getLicensePlateDisposition(data) {
        let disposition = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            disposition = licensePlateMatch.disposition;
        }

        return disposition;
    },
    getLicensePlateLastSeenDate(data) {
        let lastSeenDate = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            lastSeenDate = licensePlateMatch.lastSeenDate;
        }

        return lastSeenDate;
    },
    getLicensePlateNumber(data) {
        let licensePlateNumber = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            let country = CardService.getDisplayString(licensePlateMatch.licensePlateCountry);
            let jurisdiction = CardService.getDisplayString(licensePlateMatch.licensePlateJurisdiction);
            let number = licensePlateMatch.licensePlateCharacters;

            licensePlateNumber = country + jurisdiction + number;
        }

        return licensePlateNumber;
    },
    getLicensePlateConfidence(data) {
        let confidenceScore = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            confidenceScore = licensePlateMatch.confidence + "%";
        }

        return confidenceScore;
    },
    getLicensePlateVin(data) {
        let vin = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            vin = licensePlateMatch.vehicleIdentificationNumber;
        }

        return vin;
    },
    getLicensePlateVehicleType(data) {
        let vin = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            vin = licensePlateMatch.vehicleType;
        }

        return vin;
    },
    getLicensePlateSource(data) {
        let source = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            source = licensePlateMatch.sourceName;
        }

        return source;
    },
    getLicensePlateSourceDate(data) {
        let sourceDate = "";

        let licensePlateMatch = this.findLicensePlateMatch(data);

        if (!!licensePlateMatch) {
            sourceDate = licensePlateMatch.sourceDate;
        }

        return sourceDate;
    },
    getLicensePlateDocumentFace(data) {
        let documentFace = "";

        let datum = this.findLicensePlateNfcDatum(data);

        if (!!datum) {
            documentFace = CardService.getImageUrl(datum, "image1");
        }

        return documentFace;
    },
    getLicensePlateIsDerogatory(data) {
        let derogatory = false;

        let licensePlateDatum = this.findLicensePlateDatum(data);

        if (!!licensePlateDatum) {
            derogatory = licensePlateDatum.derogatory;
        }

        return derogatory;
    },
    isLivePhotoCaptured(data) {
        let retVal = true;

        let nfcDatum = this.findLicensePlateNfcDatum(data);
        let faceDatum = this.findLicensePlateDatum(data);
        if (
            !faceDatum ||
            !nfcDatum ||
            nfcDatum.modificationTime > faceDatum.modificationTime) {
            retVal = false;
        }

        return retVal;
    }
}

export default LicensePlateCardService;
