import Configuration from "../Configuration";
import Keycloak from "keycloak-js";

const AuthenticationService = {


    keycloakState: {},

    isKeycloakEnabled() {

        return !!Configuration.keycloakUrl
            && !!Configuration.keycloakRealm
            && !!Configuration.keycloakClientId
            && Configuration.keycloakUrl !== ""
            && Configuration.keycloakRealm !== ""
            && Configuration.keycloakClientId !== ""
            && Configuration.keycloakUrl.indexOf("_") !== 0
            && Configuration.keycloakRealm.indexOf("_") !== 0
            && Configuration.keycloakClientId.indexOf("_") !== 0;
    },
    getAuthorizationHeaderValue() {
        let authorizationHeader = "";

        if (AuthenticationService.isKeycloakEnabled()) {
            authorizationHeader = "Bearer " + AuthenticationService.keycloakState.token;
        } else if (!!Configuration.username) {
            authorizationHeader = btoa(Configuration.username + ":" + Configuration.password);
        }

        return authorizationHeader;
    },

    getAccessTokenQueryParameter() {

        return "access_token=" + AuthenticationService.keycloakState.token;
    },
    getWebsocketAuthentication() {

        let websocketAuthentication = "";
        if (AuthenticationService.isKeycloakEnabled() && !!AuthenticationService.keycloakToken) {
            websocketAuthentication = {
                token: AuthenticationService.keycloakState.token
            };
        } else if (!!Configuration.username) {
            websocketAuthentication = {
                username: Configuration.username,
                password: Configuration.password
            };
        }

        return websocketAuthentication;
    },

    getKeycloakObject() {
        return Keycloak({
            url: Configuration.keycloakUrl,
            realm: Configuration.keycloakRealm,
            clientId: Configuration.keycloakClientId,
        });
    },

    getQRCodePayloadPromise() {

        var headers = {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Authorization": AuthenticationService.getAuthorizationHeaderValue()
        };

        return fetch(Configuration.nonceEndpointUrl, {
            credentials: "include",
            method: "GET",
            headers: headers,
            mode: "cors",
            cache: "no-cache"
        });
    },
    isAuthenticated() {

        let authenticated = false;

        if (AuthenticationService.isKeycloakEnabled()) {
            authenticated = !!AuthenticationService.keycloakState &&
                AuthenticationService.keycloakState.authenticated;
        } else {
            authenticated = !!Configuration.username;
        }

        return authenticated;
    }
}

export default AuthenticationService;
