import APIMock from "../Mock/APIMock/APIMock";
import RecognizerService from "./RecognizerService";
import AuthenticationService from "./AuthenticationService";
import Configuration from "../Configuration";
import ResponseData from "../Mock/APIMock/Data/ResponseData";
import DashboardStateService from "./DashboardStateService";

const DashboardAPIService = {


    getMockRecognizers: (successCallback, errorCallback) => {


        console.debug(">>> getMockRecognizers:: ");

        if (!DashboardStateService.mockRecognizersIntervalCounter) {
            DashboardStateService.mockRecognizersIntervalCounter = 0;
        }

        ++DashboardStateService.mockRecognizersIntervalCounter;
        console.debug(">>> getMockRecognizers:: mockRecognizersIntervalCounter: ", DashboardStateService.mockRecognizersIntervalCounter);

        let data = APIMock.mockResponseData;

        console.debug("getMockRecognizers:: " + APIMock.mockTypeTestAll);

        if (APIMock.mockType === APIMock.mockTypeTestAll) {
            if (DashboardStateService.mockRecognizersIntervalCounter >= 3) {
                data = APIMock.refreshPersonModificationTime(data);
            } else if (DashboardStateService.mockRecognizersIntervalCounter >= 2) {
                data = APIMock.refreshPassportModificationTime(data);
            } else if (DashboardStateService.mockRecognizersIntervalCounter >= 1) {
                data = APIMock.refreshLicensePlateModificationTime(data);
            }
        } else if (APIMock.mockType === APIMock.mockTypeTestPassport) {
            data = APIMock.refreshPassportModificationTime(data);
        } else if (APIMock.mockType === APIMock.mockTypeTestPassportSingleEvent) {
            APIMock.mockType = APIMock.mockTypeTestPassportNoMoreEvent;
            data = APIMock.refreshPassportModificationTime(data);
        } else if (APIMock.mockType === APIMock.mockTypeTestPassportNoMoreEvent) {
            data = [];
        } else if (!APIMock.mockType || !APIMock.mockType.startsWith(APIMock.mockTypeTest) ) {

            data = APIMock.refreshLicensePlateModificationTime(data);
            let data2 = APIMock.refreshPersonModificationTimeForDesktop(ResponseData.RESPONSE1);
            data = data.concat(data2);
        } else {
            console.debug("getMockRecognizers:: Else ");
        }


        successCallback(data);
        console.debug("<<< getMockRecognizers");

    },
    getRealRecognizers: (successCallback, errorCallback) => {


        var headers = {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "Authorization": AuthenticationService.getAuthorizationHeaderValue()
        };

        fetch(Configuration.dashboardEndpointUrl + "?" + AuthenticationService.getAccessTokenQueryParameter(), {method: "GET", headers: headers})
            .then(response => response.json())
            .then(async function (result) {

                let returnValue = [];

                for (let index in result) {
                    try {
                        let item = result[index];
                        if (RecognizerService.isExpired(item)) {
                            continue;
                        }

                        let data = await fetch("/" + item.content);
                        let response = await data.json();
                        item.fetchedContent = response;
                        result[index] = item;
                        returnValue.push(item);


                    } catch (error) {
                        console.error(error);
                    }
                }

                DashboardStateService.dashboardResponseHistory.push(returnValue);
                console.debug("DashboardStateService.dashboardResponseHistory: ", DashboardStateService.dashboardResponseHistory);
                successCallback(returnValue);
            })
            .catch(errorCallback);
    },
    getRecognizers: () => {

        return new Promise((resolve, reject) => {
            let mock = DashboardStateService.isMock();

            console.debug(`>> DashboardApiService:: isMock? ${mock}`)
            if (!mock) {
                DashboardAPIService.getRealRecognizers(resolve, reject);
            } else {

                console.debug(`>> DashboardApiService:: getting mock recognizers`)
                DashboardAPIService.getMockRecognizers(resolve, reject);
            }
        });
    },
    generateUUID: () => {
        /* eslint-disable no-mixed-operators */
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (character) {
            const randomHexCharacter = Math.random() * 16 | 0;
            const v = character === 'x' ? randomHexCharacter : (randomHexCharacter & 0x3 | 0x8);
            return v.toString(16);
        });
        /* eslint-enable no-mixed-operators */
    },



};

export default DashboardAPIService;
