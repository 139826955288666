/* eslint-disable */
import "./AWebRTCWrapper.css";
import {useEffect, useRef} from "react";
import WebrtcStateService from "../../../Service/WebrtcStateService";
import DebugService from "../../../Service/DebugService";

const AWebRTCWrapper = (props) => {

    const TAG = "AWebRTCWrapper";

    let channel = props.channel;

    if (channel.endsWith("-broadcaster")) {
        channel = channel.replace("-broadcaster", "-viewer");
    } else if (!channel.endsWith("-viewer")) {
        channel = channel + "-viewer";
    }

    const headerRef = useRef();


    const handleWebRTCVideoEvent = (eventName, event) => {

        let currentElement = headerRef.current;

        if (currentElement === null) {
            return;
        }


        if ((WebrtcStateService.isVideoStreamPlaying() || WebrtcStateService.isVideoStreamPaused()) && !WebrtcStateService.isVideoStreamDisconnected()) {

            currentElement.innerHTML = props.name;

        } else if (WebrtcStateService.isVideoStreamDisconnected()) {

            currentElement.innerHTML = "";

        }
    }


    useEffect(() => {

        if (!!WebrtcStateService.callappInstance && !!WebrtcStateService.callappInstance.mAddress === channel) {
            console.debug(TAG, "Skipping WebRTC call initialization since a call is already in progress.")
            return;
        }

        console.debug(`${TAG} resetting webrtc state`, "Props are:", props, "Channel is", channel);
        WebrtcStateService.reset();

        let iceServers = props.iceServerList;


        console.debug(TAG, "Setting up client-side call. Channel:", channel, " ICE servers", iceServers);
        let uiAudio = {checked: false};
        let uiVideo = {checked: false};

        let parentElement = document.querySelector("#callapp1");
        let startButton = parentElement.querySelector(".callapp_button");

        let config = {
            getIceServers: () => {
                return iceServers;
            },
            getUiAddress: () => {
                return parentElement.querySelector(".callapp_address");
            },
            getUiAudio: () => {
                return uiAudio;
            },
            getUiVideo: () => {
                return uiVideo;
            },
            getUiUrl: () => null,
            getUiButton: () => {
                return startButton
            },
            getUiLocalVideoParent: () => null,
            getUiRemoteVideoParent: () => {
                return parentElement.querySelector(".callapp_remote_video");
            },
            mediaStream: null,
            autoStart: true
        }

        let callappInstance = apps.callapp(config);

        WebrtcStateService.callappInstance = callappInstance;

        const cleanup = () => {
            try {
                if (!!callappInstance) {
                    callappInstance.Cleanup();
                }

            } catch (error) {
                console.error(TAG, "Error while cleaning up WebRTC call", error);
            }
        }
        const SendChatMessage = (event) => {
            let message = !!event ? event.detail : null;

            if (!message || typeof message !== "string" || message.length === 0) {
                console.debug(TAG, "Message is not well-formed, could not verify that this is a non-empty string: ", message)
                return;
            }

            if (!WebrtcStateService.callappInstance ||
                !WebrtcStateService.callappInstance.mCall ||
                !WebrtcStateService.callappInstance.mCall.mConnectionInfo ||
                !WebrtcStateService.callappInstance.mCall.mConnectionInfo.mConnectionIds ||
                WebrtcStateService.callappInstance.mCall.mConnectionInfo.mConnectionIds.length === 0 ||
                !WebrtcStateService.callappInstance.mCall.mConnectionInfo.mConnectionIds[0]) {
                console.debug(TAG, "Chat data channel is not connected")
                return;
            }

            let payload = {
                Content: message,
                Reliable: true
            };

            callappInstance.mCall.Send(payload.Content, payload.Reliable);
        }

        WebrtcStateService.addWebrtcSendChatMessageEventListener(SendChatMessage);
        WebrtcStateService.addWebrtcCallEndedEventListener(cleanup);
        WebrtcStateService.addWindowUnloadEventListener(cleanup);
        WebrtcStateService.addWebrtcConnectionFailedEventListener(cleanup);


        return () => {
            console.debug(TAG, "Cleaning up useEffect. Channel: ", channel, " ICE servers", iceServers)

            WebrtcStateService.removeWebrtcConnectionFailedEventListener(cleanup);
            WebrtcStateService.removeWebrtcSendChatMessageEventListener(SendChatMessage);
            WebrtcStateService.removeWindowUnloadEventListener(cleanup);
            WebrtcStateService.removeWebrtcCallEndedEventListener(cleanup);

            cleanup();
            WebrtcStateService.callappInstance = null;
        }
    }, [channel]);

    useEffect(() => {
        WebrtcStateService.addWebrtcVideoEventListeners(handleWebRTCVideoEvent);
        return () => {
            WebrtcStateService.removeWebrtcVideoEventListeners(handleWebRTCVideoEvent);
        }
    });

    DebugService.showDebugMessage(`${TAG} ${channel}`);

    return <div data-testid={"callapp"} id="callapp1"
                data-cosentiosar-video-configuration={""}
                data-cosentiosar-video-mute-by-default={"true"}>
        <div ref={headerRef} className={"AWebRTCWrapper-Header"}>
        </div>
        <div className={"AWebRTCWrapper-Hidden"}>
            <input type="text" className="callapp_address" autoComplete="off" value={channel} readOnly={"true"}/>
            <button className=" callapp_button">View</button>
        </div>
        <div className="callapp_remote_video"></div>
    </div>;
}

export default AWebRTCWrapper;
