const LocationService = {

    init: function() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                console.debug("Geolocation API resolved: ", position.coords);

                const event = new CustomEvent('geolocation', {
                    detail: {
                        latitude : position.coords.latitude,
                        longitude : position.coords.longitude
                    }
                });
                document.dispatchEvent(event);

            });
        } else {}
    }

};

export default LocationService;
