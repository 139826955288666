const WebrtcStateService = {
    callappInstance : null,
    reset: () => {
        WebrtcStateService.callappInstance = null;
        WebrtcStateService.videoStateHistory = [];
        WebrtcStateService.isVideoStateHistoryBeingRecorded = false;
        WebrtcStateService.videoStateLatestEventHolder = {};
    },
    WebRTCVideoEventNamePrefix : "webrtc-video-event-",
    WebRTCVideoEvents : [
        "added",
        "playing",
        "waiting",
        "seeking",
        "seeked",
        "ended",
        "loadedmetadata",
        "loadeddata",
        "canplay",
        "canplaythrough",
        "durationchange",
        "timeupdate",
        "play",
        "pause",
        "ratechange",
        "volumechange",
        "suspend",
        "emptied",
        "stalled"
    ],
    eventsForInitialVideoStartPlaying : [
        "durationchange",
        "loadedmetadata",
        "loadeddata",
        "canplay",
        "canplaythrough",
        "play",
        "playing"
    ],
    eventsForVideoPlaying : [
        "play",
        "playing"
    ],
    eventsForVideoSourceDisconnected : [
        "suspend",
        "pause"
    ],
    isVideoStateHistoryBeingRecorded : false,
    videoStateHistory : [],
    videoStateLatestEventHolder : {},
    dispatchRecognizerUpdateStateEvent : (payload) => {
        const event = new CustomEvent('recognizer-update-state', {
            detail: payload
        });
        document.dispatchEvent(event);
    },
    addRecognizerFocusEventListener : (callback) => {
        document.addEventListener("recognizer-focus", callback, false);
    },
    removeRecognizerFocusEventListener : (callback) => {
        document.removeEventListener("recognizer-focus", callback, false);
    },
    dispatchRecognizerFocus: (payload) => {
        console.debug("Recognizer-focus sent with payload", payload)
        let event = new CustomEvent("recognizer-focus", {
            detail: payload
        });
        document.dispatchEvent(event);
    },
    addRecognizerUpdateStateEventListener : (callback) => {
        document.addEventListener("recognizer-update-state", callback, false);
    },
    removeRecognizerUpdateStateEventListener : (callback) => {
        document.removeEventListener("recognizer-update-state", callback, false);
    },
    addWertcReceiveChatMessageEventListener : (callback) => {
        document.addEventListener("webrtc-receive-chat-message", callback, false);
    },
    removeWebrtcReceiveChatMessageEventListener : (callback) => {
        document.removeEventListener("webrtc-receive-chat-message", callback, false);
    },
    addWebrtcSendChatMessageEventListener : (callback) => {
        document.addEventListener("webrtc-send-chat-message", callback);
    },
    removeWebrtcSendChatMessageEventListener : (callback) => {
        document.removeEventListener("webrtc-send-chat-message", callback);
    },
    dispatchWebrtcSendChatMessage : (payload) => {
        let event = new CustomEvent("webrtc-send-chat-message", {
            detail: payload
        });
        document.dispatchEvent(event);
    },
    addWindowUnloadEventListener : (callback) => {
        window.addEventListener('unload', callback);
    },
    removeWindowUnloadEventListener : (callback) => {
        window.removeEventListener('unload', callback);
    },
    addWebrtcVideoEventListeners : (callback) => {
        for (let videoEventIndex = 0; videoEventIndex < WebrtcStateService.WebRTCVideoEvents.length; videoEventIndex++ ) {
            let eventName = WebrtcStateService.WebRTCVideoEventNamePrefix + WebrtcStateService.WebRTCVideoEvents[videoEventIndex];
            document.addEventListener(eventName,
                (event) => callback(eventName, event)

            );
        }
    },
    removeWebrtcVideoEventListeners : (callback) => {
        for (let videoEventIndex = 0; videoEventIndex < WebrtcStateService.WebRTCVideoEvents.length; videoEventIndex++ ) {
            let eventName = WebrtcStateService.WebRTCVideoEventNamePrefix + WebrtcStateService.WebRTCVideoEvents[videoEventIndex];
            document.removeEventListener(eventName,
                (event) => callback(eventName, event)
            );
        }
    },
    addWebrtcCallEndedEventListener : (callback) => {
        document.addEventListener("webrtc-call-ended", callback);
    },
    removeWebrtcCallEndedEventListener : (callback) => {
        document.removeEventListener("webrtc-call-ended", callback);
    },
    addWebrtcConnectionFailedEventListener : (callback) => {
        document.addEventListener("webrtc-connection-failed", callback);
    },
    removeWebrtcConnectionFailedEventListener : (callback) => {
        document.removeEventListener("webrtc-connection-failed", callback);
    },
    startRecordingWebRTCVideoEvents : () => {
        WebrtcStateService.addWebrtcVideoEventListeners(WebrtcStateService.addSquashedVideoEventHistory);
        WebrtcStateService.isVideoStateHistoryBeingRecorded = true;
    },
    stopRecordingWebRTCVideoEvents : () => {
        WebrtcStateService.removeWebrtcVideoEventListeners(WebrtcStateService.addSquashedVideoEventHistory);
        WebrtcStateService.isVideoStateHistoryBeingRecorded = false;
    },
    addSquashedVideoEventHistory : (eventName, event) => {

        if (!WebrtcStateService.isVideoStateHistoryBeingRecorded) {
            return;
        }

        let history = WebrtcStateService.videoStateHistory;
        let lastIndex = history.length - 1;

        let historyItem = {
            time: new Date(),
            eventName,
            event
        };

        if (history.length > 0 &&
            history[lastIndex].eventName === eventName &&
            history[lastIndex].time.getTime() > new Date().getTime() - 1000
        ) {
            history[lastIndex] = historyItem;
        } else {
            history.push(historyItem);
        }

        WebrtcStateService.videoStateLatestEventHolder[eventName] = historyItem;
    },
    isVideoStreamPaused : () => {
        if (!WebrtcStateService.isVideoStateHistoryBeingRecorded) {
            return null;
        }

        let pauseEvents = ["pause"];

        let playEvents = ["play", "playing"];
        return WebrtcStateService.checkVideoEventHistoryForRecentEvents(pauseEvents, playEvents);
    },
    getLastNonRepeatingEvent : () => {
        let history = WebrtcStateService.videoStateHistory;

        let lastNonRepeatingEvent = null;
        for (let index = history.length - 1; index >= 0; index--) {
            let item = history[index];
            if (item.eventName.endsWith("timeupdate")) {
                continue;
            } else {
                lastNonRepeatingEvent = history[index];
                break;
            }
        }

        return lastNonRepeatingEvent;
    },
    getLastItemWithEventName : (eventName) => {

    },
    checkVideoEventHistoryForRecentEvents : (recentEvents, requiredNonOccurringEvents = []) => {
        let history = WebrtcStateService.videoStateHistory;

        if (history.length === 0) {
            return false;
        }

        let lastHistoryItem = WebrtcStateService.getLastNonRepeatingEvent();
        let lastEventEpochTimeInMilliseconds = lastHistoryItem.time.getTime();

        let requiredNonOccurringEventTimestamps = requiredNonOccurringEvents.map((requiredNonOccurringEvent) => {
            let foundEvents = history.filter( value => value.eventName === WebrtcStateService.WebRTCVideoEventNamePrefix + requiredNonOccurringEvent);

            let foundEvent = null;
            if (foundEvents.length > 0) {
                foundEvent = foundEvents[foundEvents.length - 1];
            }
            return foundEvent
        }).filter((value => value != null)).map((value) => value.time.getTime());


        let isAnyRecentlyEventNotPresentWithinLastFiveSeconds = recentEvents.map((recentEventName) => {
            let foundEvents = history.filter( value => value.eventName === WebrtcStateService.WebRTCVideoEventNamePrefix + recentEventName);

            let isEventOccurredRecently = false;

            if (foundEvents.length > 0) {
                let lastRecentEventItem = foundEvents[foundEvents.length - 1];
                let lastRecentEventItemTimestamp = lastRecentEventItem.time.getTime();
                let lastRecentEventEpochTimeInMilliseconds = lastRecentEventItemTimestamp;

                isEventOccurredRecently = lastEventEpochTimeInMilliseconds - lastRecentEventEpochTimeInMilliseconds < 5000;

                if (isEventOccurredRecently) {
                    let requiredNonOccurrenceViolated = requiredNonOccurringEventTimestamps.map((timestamp) => {
                        return timestamp > lastRecentEventItemTimestamp;
                    }).includes(true);

                    isEventOccurredRecently = !requiredNonOccurrenceViolated;
                }
            }

            return isEventOccurredRecently;

        }).includes(false);

        let foundAllEventsOccurredRecentRelativeToLastEvent = !isAnyRecentlyEventNotPresentWithinLastFiveSeconds;
        return foundAllEventsOccurredRecentRelativeToLastEvent;
    },

    isVideoStreamDisconnected : () => {
        if (!WebrtcStateService.isVideoStateHistoryBeingRecorded) {
            return null;
        }

        let isDisconnected = false;
        let isSuspnedAfterPlay = WebrtcStateService.isEventOccurringAfter("play", "suspend");
        if (isSuspnedAfterPlay) {
            let isPauseAfterSuspendWithin5Seconds = WebrtcStateService.isEventOccurringAfter("suspend", "pause", 5000);

            isDisconnected = isPauseAfterSuspendWithin5Seconds;
        }

        return isDisconnected;
    },
    isVideoStreamPlaying : () => {
        if (!WebrtcStateService.isVideoStateHistoryBeingRecorded) {
            return null;
        }

        let isPlaying = false;

        let isPlayAfterPause = WebrtcStateService.isEventOccurringAfter("pause", "play");

        if (isPlayAfterPause) {
            let isPlayWithin5SecondsOfTimeUpdate = WebrtcStateService.isEventOccurringAfter("play", "timeupdate");
            isPlaying = isPlayWithin5SecondsOfTimeUpdate;
        }

        return isPlaying;
    },
    getLatestEventTimeInMilliseconds: (eventName) => {
        let latestPlayEvent = WebrtcStateService.videoStateLatestEventHolder[WebrtcStateService.WebRTCVideoEventNamePrefix + eventName];
        let latestPlayEventEpochTimeInMilliseconds = -1;
        if (latestPlayEvent && latestPlayEvent.time) {
            latestPlayEventEpochTimeInMilliseconds = latestPlayEvent.time.getTime();
        }

        return latestPlayEventEpochTimeInMilliseconds;
    },
    isEventNotOccurringAfter : (priorEventName, laterEventName, maxDifference = 0) => {
        return !WebrtcStateService.isEventOccurringAfter(priorEventName, laterEventName, maxDifference);
    },
    isEventOccurringAfter : (priorEventName, laterEventName, maxDifference = 0) => {
        let priorEventTimeInMilliseconds = WebrtcStateService.getLatestEventTimeInMilliseconds(priorEventName);
        let laterEventTimeInMilliseconds = WebrtcStateService.getLatestEventTimeInMilliseconds(laterEventName);

        let returnValue = false;
        if (priorEventTimeInMilliseconds > 0 && laterEventTimeInMilliseconds > 0) {
            returnValue = priorEventTimeInMilliseconds < laterEventTimeInMilliseconds;
            if (maxDifference > 0) {
                returnValue = (laterEventTimeInMilliseconds - priorEventTimeInMilliseconds) < maxDifference;
            }
        } else if (priorEventTimeInMilliseconds <= 0) {
            returnValue = true;
        } else if (laterEventTimeInMilliseconds <= 0) {
            returnValue = false;
        }
        return returnValue;
    }
};

export default WebrtcStateService;
