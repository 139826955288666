import React from "react";
import "./QRCodeScreen.css";
import AuthenticationService from "../../Service/AuthenticationService";
import QRCode from "qrcode.react";

const QRCodeScreen = (props) => {

  const [state, setState] = React.useState({payload: {}, minSize: 0});

  let snippet = <span>Loading...</span>;

  if (!!state && !!state.error) {
    snippet = <span>{state.error}</span>;
  } else if (!!state && !!state.payload && !!state.payload.url && !!state.minSize
    && state.minSize > 0) {
    snippet = <QRCode value={state.payload.url} size={state.minSize} includeMargin={true}></QRCode>;
  }

  React.useEffect(function () {

    let keycloakState = AuthenticationService.keycloakState;

    if (!!keycloakState && keycloakState.authenticated) {

      let qrCodePayloadPromise = AuthenticationService.getQRCodePayloadPromise();

      qrCodePayloadPromise
        .then(response => response.json())
        .then(function (result) {

        let win = window,
          doc = document,
          docElem = doc.documentElement,
          body = doc.getElementsByTagName('body')[0],
          x = win.innerWidth || docElem.clientWidth || body.clientWidth,
          y = win.innerHeight || docElem.clientHeight || body.clientHeight;

        let min = x < y ? x : y;

        setState({
          payload: result,
          minSize: min
        });
      })
        .catch(function (error) {


          let errorMessage = "Could not retrieve QR code payload.";
          console.error(errorMessage, error);
          setState({
            payload: {},
            error: errorMessage
          });
        });

    } else {
      let errorMessage = "Could not authenticate.";
      console.error(errorMessage);
      setState({
        payload: {},
        error: errorMessage
      });
    }
  }, []);


  return (
    snippet
  );

}

export default QRCodeScreen;
