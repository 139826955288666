import Recognizers from "./Recognizers";
import React from "react";
import DashboardStateService from "../../../Service/DashboardStateService";

const RecognizersContainer = () => {

    const TAG = ">> RecognizersContainer::";
    const [state, setState] = React.useState({markers: []});

    let handleRecognizersUpdated = (event) => {
        let markers = event.detail;
        console.debug(`${TAG} handle recognizers updated, ${markers.length} markers`)
        if (markers && markers.length) {
            let newState = {
                ...state,
                markers: markers
            };
            newState[Math.random() + ""] = Math.random();
            setState(() => newState);
        } else {
            if (!state || !state.markers || state.markers > 0) {
                setState({
                    markers: []
                });
            }
        }
    }

    React.useEffect(function () {

        // if (DashboardStateService.isPollingForRecognizers) {
        //     return;
        // }
        console.debug(TAG + "useEffect - start polling for recognizers and listen for recognizer update events")

        DashboardStateService.startPollingForRecognizers();
        DashboardStateService.addRecognizersUpdatedEventHandler(handleRecognizersUpdated);
        return () => {

            DashboardStateService.removeRecognizersUpdatedEventHandler(handleRecognizersUpdated);
            DashboardStateService.stopPollingForRecognizers();

        }
        // eslint-disable-next-line
    }, []);
    //
    // useEffect(() => {
    //
    //     let expungeExpiredMarkers = () => {
    //         let markers = state.markers;
    //         markers.filter((marker => RecognizerService.isExpired(marker)));
    //     }
    //
    //     let intervalId = setInterval(expungeExpiredMarkers, 500);
    //
    //
    //     return () => {
    //
    //         clearInterval(intervalId);
    //     }
    // });

    console.debug(`${TAG} Rendering markers, ${state.markers.length} markers`)
    return <div data-testid={"RecognizersContainer"}><Recognizers markers={state.markers}/></div>;
}
export default RecognizersContainer;
