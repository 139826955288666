import CardService from "../../Service/CardService";
import TYPES from "../../Component/MapboxMapContainer/Recognizers/Types";
import moment from "moment";
import DeviceData from "./Data/DeviceData";
import ResponseData from "./Data/ResponseData";

const APIMock = {
    mockType : "",
    mockResponseData : ResponseData.RESPONSE1,
    mockTypeTestAll : "test-all",
    mockTypeTestSimultaneousAll : "test-simultaneous-all",
    mockTypeTestPassport : "test-passport",
    mockTypeTestPassportSingleEvent : "test-passport-single-event",
    mockTypeTestPassportNoMoreEvent : "test-passport-no-more-events",
    mockTypeTest : "test",

    filterData: function(inputData, id) {
        let origData = JSON.parse(JSON.stringify(inputData));

        let data = [];

        for (let key in origData) {
            let datum = origData[key];
            if (id !== datum.id) {
                continue;
            }
            data.push(datum);
        }

        return data;
    },
    refreshDatum: function(data, type) {
        let datum = CardService.findDatum(data, type);
        datum.modificationTime = new Date().getTime();

        console.log("datum updated ", datum.type, "\t", datum.modificationTime, "\t", datum.image)

        this.updateImageAttribute(datum);
    },
    makeDatumStale: function(data, type) {
        let datum = CardService.findDatum(data, type);
        datum.modificationTime = moment().subtract(10, 'days').valueOf();

        console.log("datum updated ", datum.type, "\t", datum.modificationTime, "\t", datum.image)

        this.updateImageAttribute(datum);
    },
    updateImageAttribute: function (datum) {
        if (!!datum.image) {
            let imagesObject = JSON.parse(datum.image);
            if (!!imagesObject) {
                for (let key in imagesObject) {
                    let image = imagesObject[key];
                    if (!!image && !image.startsWith("http")) {
                        imagesObject[key] = "https://lab.cosentios.com/" + imagesObject[key];
                    }
                }
                datum.image = JSON.stringify(imagesObject);
            }
        }
    }, refreshPassportModificationTime : function(data) {
        data = this.filterData(data, DeviceData.PIXEL_4_DEVICE_ID);

        // this.refreshDatum(data, TYPES.PASSPORT_NFC_DATA.id);
        this.refreshDatum(data, TYPES.PASSPORT_MRZ.id);
        this.refreshDatum(data, TYPES.PASSPORT_MRZ_BIOGRAPHIC.id);
        this.refreshDatum(data, TYPES.PASSPORT_FACE.id);

        return data;
    },
    refreshLicensePlateModificationTime : function(data) {
        data = this.filterData(data, DeviceData.PIXEL_4_DEVICE_ID);

        this.refreshDatum(data, TYPES.LICENSE_PLATE.id);
        return data;
    },
    refreshPersonModificationTime : function(data) {
        data = this.filterData(data, DeviceData.PIXEL_4_DEVICE_ID);

        this.refreshDatum(data, TYPES.FACE.id);
        return data;
    },
    refreshPersonModificationTimeForDesktop : function(data) {
        data = this.filterData(data, DeviceData.DESKTOP_DEVICE_ID);

        this.refreshDatum(data, TYPES.FACE.id);
        return data;
    }

};


export default APIMock;
