import React, {useEffect} from "react";
import bluePin from '../../../../Images/blue-pin.svg';
import redPin from '../../../../Images/red-pin.svg';
import CardPopup from "../../CardPopup/CardPopup";
import CardService from "../../../../Service/CardService";
import DashboardStateService from "../../../../Service/DashboardStateService";
import MarkerContainer from "./MarkerContainer/MarkerContainer";
import WebrtcStateService from "../../../../Service/WebrtcStateService";
import CardStateService from "../../../../Service/CardStateService";


const Recognizer = (props) => {

    const TAG = ">> Recognizer::";

    const [popup, setPopup] = React.useState();

    const index = props.index;

    let datum = {};

    console.debug(TAG + "Rendering recognizer for data", props.data);

    if (!!props.data && props.data.length > 0) {

        datum = props.data[0];
        console.debug(TAG + "Selecting first data list item to be datum for latitutde, longitude, id , and type determination", datum);
    }

    const coordinates = [datum.lat, datum.lon];


    console.debug(TAG + "Use effect");
    React.useEffect(function () {

        if (!DashboardStateService.appLocationSetByMarker) {
            console.debug(TAG + "Dispatching event for marker location");
            DashboardStateService.dispatchMarkerLocation({
                latitude: datum.lat,
                longitude: datum.lon
            });
        }

        CardService.addDismissPopupEventHandler(dismissCardPopup)


        return function () {
            CardService.removeDismissPopupEventHandler(dismissCardPopup)
        }
    });

    const dismissCardPopup = (event) => {

        if (!!event) {
            if (!!event.detail) {
                if (datum.id === event.detail.excludedId) {
                    return;
                }
            }
        }

        if (popup) {
            CardStateService.reset();
            setPopup(null);
        }
    }

    function togglePopup() {
        console.debug(TAG + "Toggled popup");
        if (!!popup) {
            dismissCardPopup();
        } else {
            CardService.dismissCardsExcept(datum.id)
            setPopup({
                latitude: coordinates[0],
                longitude: coordinates[1],
                closeOnClick: false,
                offsetLeft: 16,
                onClose: function () {
                    dismissCardPopup();
                },
            })
        }
    }

    const clickHandler = () => {
        togglePopup();

        console.debug(`${TAG} Dispatching recognizer focus, properties are: `, props, "and value of !popup is ", !popup);
        if (!popup) {
            let id = props.data.length ? props.data[0].id : props.data.id;
            let name = props.data.length ? props.data[0].name : props.data.name;
            WebrtcStateService.dispatchRecognizerFocus({ id : id , name : name });
        } else {
            WebrtcStateService.dispatchRecognizerFocus(false);
        }
    }

    function getPopupFragment() {
        if (!popup || !datum || !datum.fetchedContent ||
            datum.fetchedContent.length === 0
        ) {
            return <React.Fragment></React.Fragment>;
        }


        return <CardPopup index={index} popup={popup} data={props.data}></CardPopup>;
    }

    let popupFragment = getPopupFragment();

    let pinSource = CardService.hasDerogatory(props.data) ? redPin : bluePin;

    useEffect(() => {

        if (!!popup) {
            CardService.dispatchDataUpdate(props.data);
        }

        return () => {

        }
    }, [popup, props.data])
    console.debug(TAG + "Rendering marker at coordinates", coordinates);
    let markerFragment = !!coordinates ?
        <MarkerContainer
            index={index}
            latitude={coordinates[0]}
            longitude={coordinates[1]}
        >
            <img data-recognizerid={props.data[0].id} data-recognizertype={props.data[0].type} data-testid={`Marker-${index}`} className={"MapboxMapContainer-Pin"} alt={"Marker Pin"} src={pinSource} onClick={clickHandler}/>
        </MarkerContainer> : <React.Fragment/>;

    console.debug(TAG + "Rendering");
    return <React.Fragment>
        {popupFragment}
        {markerFragment}
    </React.Fragment>
}


export default Recognizer;
