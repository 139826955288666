import React from "react";

const DebugService = {
    DebugEnabled: false,
    DebugShowing: false,
    DebugElement: null,
    DebugElementId: "debug-window-fragment",
    getDebugFragment(json) {
        const jsonString = JSON.stringify(json);

        return <div style={{
            "background": "white",
            position: "fixed",
            top: "0px",
            left: "0px",
            width: "200px",
            height: "200px",
            zIndex: "1000",
            overflow: "scroll"
        }}>{jsonString}</div>;
    },
    addDebugElement: () => {
        if (!DebugService.DebugShowing) {

            let element = document.querySelector("#" + DebugService.DebugElementId);
            if (!!element) {
                DebugService.DebugElement = element;
                return;
            }

            let div = document.createElement("div");
            div.style.background = "white";
            div.style.position = "fixed";
            div.style.top = "0px";
            div.style.left = "0px";
            div.style.width = "25%";
            div.style.height = "50%";
            div.style.zIndex = "1000";
            div.style.overflow = "scroll";
            div.id = DebugService.DebugElementId;
            div.innerHTML = "<button onclick=\"this.parentElement.innerHTML=this.parentElement.innerHTML.substring(0,107)\">Clear</button>";

            document.body.append(div);
            DebugService.DebugElement = div;
        }
    },
    showDebugMessage: (message) => {
        if (!DebugService.DebugEnabled) {
            return;
        }

        DebugService.addDebugElement();

        DebugService.DebugElement.innerHTML =  DebugService.DebugElement.innerHTML + "<br />" + message;

    }
};

export default DebugService;
