const ThemeService = {
    isDarkTheme : () => {
        return window.location.href.indexOf("#dark") !== -1;
    },
    addWindowResizeListener : (callback) => {

        window.addEventListener('resize', callback)
    },

    removeWindowResizeListener : (callback) => {

        window.removeEventListener('resize', callback)
    }
}
export default ThemeService;
