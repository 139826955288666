import {Route, Switch} from "react-router-dom";
import React, {Fragment} from "react";
import AuthenticationService from "./Service/AuthenticationService";
import Header from "./Component/Header/Header";
import Home from "./Component/Home/Home";
import DashboardMap from "./Component/DashboardMap/DashboardMap";
import QRCodeScreen from "./Component/QRCode/QRCodeScreen";
import "./App.css";
import ThemeService from "./Service/ThemeService";

const App = (props) => {

    let snippet = <Fragment/>;

    const [state, setState] = React.useState({
        keycloakState: null,
        attemptedAuthentication: false
    });

    React.useEffect(function () {

            async function initializer() {
                if (!state.keycloakState && !state.attemptedAuthentication) {

                    let authenticationFailedHandler = () => {
                        setState({keycloakState: null, authenticated: false, attemptedAuthentication: true});
                    }

                    let authenticationSuccessfulHandler = (keycloak) => {
                        AuthenticationService.keycloakState = keycloak;
                        setState({keycloakState: keycloak, authenticated: true, attemptedAuthentication: true});
                    }


                    if (AuthenticationService.isKeycloakEnabled()) {
                        let keycloakObject = AuthenticationService.getKeycloakObject();
                        let keycloakPromise = keycloakObject.init({});

                        keycloakPromise.then(async authenticated => {
                            if (authenticated) {
                                authenticationSuccessfulHandler(keycloakObject);
                            } else {
                                authenticationFailedHandler(keycloakObject);
                            }
                        })

                        keycloakPromise.catch(authenticationFailedHandler);

                        let result = await keycloakPromise;
                        console.log(result);


                        if (!keycloakObject.authenticated) {
                            keycloakObject?.login({
                                redirectUri: window.location.href
                            });
                        }
                    }

                }
            }

            initializer();
        }
    );

    if (AuthenticationService.isAuthenticated()) {
        snippet =
            <Switch>
                <Route path={"/map"}>
                    <DashboardMap/>
                </Route>
                <Route path={"/qr-code"}>
                    <QRCodeScreen/>
                </Route>
                <Route path={"/"}>
                    <Header/>
                    <Home/>
                </Route>
            </Switch>;
    }

    let outerCss = "";
    if (ThemeService.isDarkTheme()) {
        outerCss += " DarkTheme ";
    }

    return (
        <div className={`App ${outerCss}`}>
            {snippet}
        </div>


    );
}

export default App;
