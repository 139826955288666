import React from "react";
import Recognizer from "./Recognizer/Recognizer";

const Recognizers = (props) => {

    const TAG = ">> Recognizers::"
    const markers = !!props ? props.markers : [];

    console.debug(`${TAG} ${markers.length} markers to be rendered`)
    return (<React.Fragment>
        {markers.map( (marker, index) => {
            return <Recognizer index={index} key={index} data={marker} />;
        })}
    </React.Fragment>);

}

export default Recognizers;
