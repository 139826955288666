import "./TextInput.css";
import {useEffect, useRef, useState} from "react";

const TextInput = (props) => {

    let placeholder = "Send a message to everyone";

    let textareaRef = useRef();
    const [state, setState] = useState({textareaValue : "", hasFocus: false});



    const handleOnFocus = () => {
        setState({
            ...state,
            hasFocus : true
        })
    }
    const handleOnBlur = () => {
        setState({
            ...state,
            hasFocus : false
        })
    }

    const handleOnChange = (event) => {
        setState({
            ...state,
            textareaValue : event.target.value
        })
    }

    const sendMessage = () => {
        let value = textareaRef.current.value;
        if (typeof value === "string" && value.length > 0) {
            props.onSendMessage(value);
            setState({
                ...state,
                textareaValue : ""
            })
        }

    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            if(event.shiftKey){

                const numberOfNewLinesInTargetText = event.target.value.split(/\r\n|\r|\n/).length;

                let newTextareaValue = event.target.value;
                if (numberOfNewLinesInTargetText < 5) {
                    newTextareaValue = newTextareaValue + "\n";
                }

                setState({
                    ...state,
                    textareaValue : newTextareaValue
                });
            } else {
                sendMessage();
            }
            event.preventDefault();
        }
    }
    let outerCss = "";
    if (state.hasFocus) {
        outerCss += " TextInput-HasFocus ";
    }

    let displayText = "";
    if (state.textareaValue) {
        displayText = state.textareaValue;
    }

    let displayedPlaceholder = placeholder;

    if (state.hasFocus) {
        displayedPlaceholder = "";
    }

    if (typeof state.textareaValue === "string" && state.textareaValue && state.textareaValue.length > 0) {
        displayedPlaceholder = "";
    }

    let textAreaRows = 0;

    const numberOfNewLinesInTargetText = state.textareaValue.split(/\r\n|\r|\n/).length;
    textAreaRows = numberOfNewLinesInTargetText;

    if (textareaRef.current && textareaRef.current.scrollHeight) {
        let initialHeight = textareaRef.current.getAttribute("data-initial-client-height");
        if (!initialHeight) {
            textareaRef.current.setAttribute("data-initial-client-height", textareaRef.current.clientHeight);
        }

        let lineHeight = textareaRef.current.scrollHeight / parseInt(initialHeight);
        let diffLineHeight = lineHeight - textAreaRows;
        textAreaRows += diffLineHeight;
    }

    if (!state.textareaValue) {
        textAreaRows = 1;
    }

    if (textAreaRows > 5) {
       textAreaRows = 5;
    }

    if (state.hasFocus) {
        outerCss += " TextInput-TextAreaBoxShadow ";
    } else {
        outerCss += " TextInput-TextAreaBoxShadowLite ";
    }



    const handleKepUp = () => {
        // if (textareaRef.current.scrollHeight > textareaRef.current.clientHeight) {
        //     textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
        // }
    }
    useEffect(() => {

        props.adjustLayout();
    });

    const textInputTextAreaContainer = <div className={"TextInput-TextArea-Container"}>
                <textarea className={"TextInput-TextArea"}
                          onFocus={handleOnFocus}
                          onBlur={handleOnBlur}
                          value={displayText}
                          onChange={handleOnChange}
                          onKeyPress={handleKeyPress}
                          onKeyUp={handleKepUp}
                          ref={textareaRef}
                          rows={textAreaRows}
                          maxLength={500}
                          tabIndex={0}
                ></textarea>
    </div>;

    const textInputTextareaPlaceholder = <div className={"TextInput-TextAreaPlaceholder"}>
        {displayedPlaceholder}
    </div>;

    const textInputSendButton = <div className={"TextInput-SendButton"} aria-label={placeholder} role={"button"} onClick={sendMessage}>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"
             className="TextInput-SendIcon">
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
    </div>;
    return <div  className={`TextInput-Container ${outerCss}` }>
        <div className={"TextInput-TextAreaContainer"}>

            {textInputTextareaPlaceholder}

            {textInputTextAreaContainer}

        </div>


        {textInputSendButton}
    </div>;
}

export default TextInput;
