import {Fragment} from "react";
import PassportCard from "../Component/MapboxMapContainer/CardPopup/PassportCard/PassportCard";
import TYPES from "../Component/MapboxMapContainer/Recognizers/Types";
import PersonCard from "../Component/MapboxMapContainer/CardPopup/PersonCard/PersonCard";
import LicensePlateCard from "../Component/MapboxMapContainer/CardPopup/LicensePlateCard/LicensePlateCard";
import CardService from "./CardService";
import Configuration from "../Configuration";
import PassportCardService from "./PassportCardService";

const CardStateService = {

    snippetHistory: [],

    reset: () => {
        console.debug(">> CardStateService: resetting snippet history");
        CardStateService.snippetHistory = [];
    },
    getTypesFromSnippet : (snippet) => {
        let data = snippet.data;

        let types = {
            hasPassportType : false,
            hasPersonType : false,
            hasLicensePlateType : false
        };

        for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
            let datum = data[dataIndex];
            let datumTypes = CardService.getTypesFromDatum(datum);
            types.hasPassportType = types.hasPassportType || datumTypes.hasPassportType;
            types.hasPersonType = types.hasPersonType || datumTypes.hasPersonType;
            types.hasLicensePlateType = types.hasLicensePlateType || datumTypes.hasLicensePlateType;
        }

        return types;
    },
    getSnippetMemoryInterval: (type) => {
        let returnValue = Configuration.snippetHistoryExpirationDurationInMilliseconds;

        if (type !== TYPES.PASSPORT && type !== TYPES.PASSPORT.id) {
            returnValue = Configuration.snippetHistoryExpirationDurationInMilliseconds / 4;
        }

        return returnValue;
    },
    putLatestSnippet(type, data) {
        console.debug(">> CardStateService:: Put latest snippet")
        let latestSnippet = {
            timestampInMilliseconds: new Date().getTime(),
            type: type,
            data: data
        };
        CardStateService.snippetHistory.push(latestSnippet);
        console.debug(">> CardStateService:: latest snippet history", CardStateService.snippetHistory);
        return latestSnippet;
    },
    getComponentForLatestSnippet : () => {
        let snippet = CardStateService.getLatestSnippet();

        let component = <Fragment />;

        if (snippet && snippet.data) {
            let types = CardStateService.getTypesFromSnippet(snippet);
            if (types.hasPassportType) {
                component = <PassportCard data={snippet.data}/>;
            } else if (types.hasPersonType) {
                component = <PersonCard data={snippet.data}/>
            } else if (types.hasLicensePlateType) {
                component = <LicensePlateCard data={snippet.data}/>;
            }
        }

        return component;
    },
    getLatestSnippet() {
        console.debug(">> CardStateService:: Getting latest snippet", CardStateService.snippetHistory)
        let snippetHistory = CardStateService.snippetHistory;
        let currentTimestampInMilliseconds = new Date().getTime();
        let returnSnippet = {};

        let validatedSnippetHistory = snippetHistory.filter(snippet =>
            snippet &&
            snippet.timestampInMilliseconds &&
            snippet.type &&
            snippet.data &&
            currentTimestampInMilliseconds < (snippet.timestampInMilliseconds + CardStateService.getSnippetMemoryInterval(snippet.type))
        ).sort((a, b) =>
            a.timestampInMilliseconds > b.timestampInMilliseconds ?
                1 : b.timestampInMilliseconds > a.timestampInMilliseconds ?
                -1 : 0);

        CardStateService.snippetHistory = validatedSnippetHistory;

        const isDetectingPassports = validatedSnippetHistory.some(snippet => CardService.isPassportCard(snippet.type));

        let hasPassportType = false;
        let passportIndex = -1;
        let hasLicensePlateType = false;
        let licensePlateIndex = -1;
        let hasPersonType = false;
        let personIndex = -1;

        console.debug(">> CardStateService:: Validated Snippet History with length ", validatedSnippetHistory.length, validatedSnippetHistory)
        for (let validTypeIndex = validatedSnippetHistory.length - 1; validTypeIndex >= 0; validTypeIndex--) {


            let currentSnippet = validatedSnippetHistory[validTypeIndex];

            let types = CardStateService.getTypesFromSnippet(currentSnippet);
            console.debug(`>> CardStateService:: [hasPassportType? ${hasPassportType} \n passportIndex? ${passportIndex}` +
                `\n hasLicensePlateType? ${hasLicensePlateType} licensePlateIndex? ${licensePlateIndex} ` +
                `\n hasPersonType? ${hasPersonType} personIndex? ${personIndex} ` +
                `\n isDetectingPassports ${isDetectingPassports}] :: Iterating index[${validTypeIndex}]`, types, currentSnippet)
            if (types.hasPassportType || (isDetectingPassports && types.hasPersonType)) {

                console.debug(">>> CardStateService:: hasPassportType?" + types.hasPassportType + " (isDetectingPassports && types.hasPersonType)? " + (isDetectingPassports && types.hasPersonType) );
                let isNewSnippetTypePerson = (isDetectingPassports && types.hasPersonType);

                if (hasPassportType) {
                    let isCurrentSnippetTypePerson = CardService.isPersonCard(validatedSnippetHistory[passportIndex].type);

                    if (isCurrentSnippetTypePerson && !isNewSnippetTypePerson) {
                        console.debug(">> CardStateService:: Updating passport index to new snippet since current is person type")
                        passportIndex = validTypeIndex;
                        continue;
                    }

                    let latestSnippet = validatedSnippetHistory[passportIndex];

                    if (isNewSnippetTypePerson) {
                        console.debug(">> CardStateService:: Keeping latest entry since newer entry is the face comparison image")
                        continue;
                    } else if (latestSnippet.timestampInMilliseconds > currentSnippet.timestampInMilliseconds) {
                        console.debug(">> CardStateService:: Checking to keep latest entry since it's newer")
                        if (latestSnippet.data.length >= currentSnippet.data.length) {
                            console.debug(">> CardStateService:: Keeping newer entry since they're in similar phase of the passport detection process as the older one")
                            continue;
                        } else {
                            let latestPassportNumber = PassportCardService.getPassportDocumentNumber(latestSnippet.data);
                            let newPassportNumber = PassportCardService.getPassportDocumentNumber(currentSnippet.data);
                            if (latestPassportNumber !== newPassportNumber) {
                                console.debug(">> CardStateService:: Skipping to keep latest entry since it is a different passport number")
                                continue;
                            }
                        }
                    }
                }
                hasPassportType = true;
                passportIndex = validTypeIndex;
                console.debug(">> CardStateService:: passportIndex=" + validTypeIndex );
            } else if (types.hasPersonType) {
                console.debug(">> CardStateService:: person type")
                if (hasPersonType) {
                    let latestSnippet = validatedSnippetHistory[personIndex];
                    if (latestSnippet.timestampInMilliseconds > currentSnippet.timestampInMilliseconds) {
                        console.debug(">> CardStateService:: Skipping current entry since it's older than the current person snippet ")
                        continue;
                    }
                }
                hasPersonType = true;
                personIndex = validTypeIndex;
                console.debug(">> CardStateService:: personIndex=" + validTypeIndex );
            } else if (types.hasLicensePlateType) {
                console.debug(">> CardStateService:: license plate type")
                if (hasLicensePlateType) {
                    let latestSnippet = validatedSnippetHistory[licensePlateIndex];
                    if (latestSnippet.timestampInMilliseconds > currentSnippet.timestampInMilliseconds) {
                        console.debug(">> CardStateService:: Skipping current entry since it's older than the current license plate snippet ")
                        continue;
                    }
                }
                hasLicensePlateType = true;
                licensePlateIndex = validTypeIndex;
                console.debug(">> CardStateService:: licensePlateIndex=" + validTypeIndex );
            }
        }

        if (hasPassportType) {
            console.debug(">>CardStateService::getLatestSnippet PassportCard", validatedSnippetHistory[passportIndex]);
            returnSnippet = validatedSnippetHistory[passportIndex];

        } else if (hasPersonType) {
            console.debug(">>CardStateService::getLatestSnippet PersonCard", validatedSnippetHistory[personIndex]);
            returnSnippet = validatedSnippetHistory[personIndex];
        } else if (hasLicensePlateType) {
            console.debug(">>CardStateService::getLatestSnippet LicensePlateCard", validatedSnippetHistory[licensePlateIndex]);
            returnSnippet = validatedSnippetHistory[licensePlateIndex];

        }

        console.debug(">> CardStateService:: Returning Snippet", returnSnippet)
        return returnSnippet;
    }

};

export default CardStateService;
