import TYPES from "../Component/MapboxMapContainer/Recognizers/Types";
import CardService from "./CardService";

const PassportCardService = {
    findComparisonMatch(data) {

        let datum = CardService.findDatum(data, TYPES.PASSPORT_FACE.id);


        return PassportCardService.getFirstFetchedContentWithProperty(datum, "faceComparisonMatch");
    },
    getFirstFetchedContentWithProperty(datum, property) {
        let retVal = null;
        if (datum && datum.fetchedContent && datum.fetchedContent.length && datum.fetchedContent.length > 0) {
            for (let fetchedContentIndex = 0; fetchedContentIndex < datum.fetchedContent.length; fetchedContentIndex++) {
                let fetchedContent = datum.fetchedContent[fetchedContentIndex];
                if (!!fetchedContent[property]) {
                    retVal = fetchedContent[property];
                    break;
                }
            }
        }

        return retVal;
    },
    findPassporMrzBiographicDatum(data) {
        return CardService.findDatum(data, TYPES.PASSPORT_MRZ_BIOGRAPHIC.id);
    },
    findPassportFaceDatum(data) {
        return CardService.findDatum(data, TYPES.PASSPORT_FACE.id);
    },
    findPassportNfcDatum(data) {
        return CardService.findDatum(data, TYPES.PASSPORT_NFC_DATA.id);
    },
    findPassportMatch(data) {
        let datum = CardService.findDatum(data, TYPES.PASSPORT_NFC_DATA.id);
        if (!datum) {
            datum = CardService.findDatum(data, TYPES.PASSPORT_MRZ_BIOGRAPHIC.id);
        }
        return PassportCardService.getFirstFetchedContentWithProperty(datum, "passportMatch");
    },
    getPassportLiveFace(data) {
        let liveFace = "";

        let datum = this.findPassportFaceDatum(data);
        if (!!datum) {
            let passportDatum = this.findPassportNfcDatum(data);
            if (!passportDatum) {
                passportDatum = this.findPassporMrzBiographicDatum(data);
            }
            liveFace = CardService.getImageUrlWithTimestamp(datum, "image2", passportDatum.modificationTime);
        }
        return liveFace;
    },
    getPassportDocumentMatch(data) {
        let match = "";

        let comparisonMatch = this.findComparisonMatch(data);

        if (!!comparisonMatch) {
            match = comparisonMatch.match ? "MATCH" : "NO MATCH";
        }

        return match;
    },
    getPassportDocumentMatchCss(data) {
    },
    getPassportLiveFaceCss(data) {
    },
    getPassportLiveColumnCss(data) {
        let css = "PassportCard-LiveColumn-Hidden";

        if (this.isLivePhotoCaptured(data)) {
          css = "";
        }

        return css;
    },
    getPassportDocumentExpiration(data) {

        let expiration = "";

        let passportMatch = this.findPassportMatch(data);

        if (!!passportMatch) {
            expiration = passportMatch.expiration;
        }

        return expiration;
    },
    isPassportDocumentExpired(data) {
        let isExpired = false;

        let expirationDate = this.getPassportDocumentExpiration(data);
        if (!!expirationDate) {
            isExpired = Date.parse(expirationDate) < new Date().getTime();
        }

        return isExpired;
    },
    getPassportDocumentNumber(data) {
        let passportNumber = "";

        let passportMatch = this.findPassportMatch(data);

        if (!!passportMatch) {
            passportNumber = passportMatch.passportNumber;
        }

        return passportNumber;
    },
    getPassportDocumentNumberCss(data) {
    },
    getPassportDocumentGenderDob(data) {
        let genderDob = "";

        let passportMatch = this.findPassportMatch(data);

        if (!!passportMatch) {
            let gender = passportMatch.gender;
            if (!gender || gender.length === 0) {
                gender = "";
            } else {
                gender = gender.substr(0, 1).toUpperCase();
            }

            genderDob = passportMatch.gender + " " + passportMatch.dateOfBirth;
        }

        return genderDob;
    },
    getPassportDocumentGenderDobCss(data) {

    },
    getPassportDocumentName(data) {
        let documentName = "";

        let passportMatch = this.findPassportMatch(data);

        if (!!passportMatch) {
            documentName = passportMatch.firstName + " " + passportMatch.lastName;
        }

        return documentName;
    },
    getPassportDocumentNameCss(data) {
    },
    getPassportDocumentFace(data) {
        let documentFace = "";

        let datum = this.findPassportNfcDatum(data);

        if (!!datum) {
            documentFace = CardService.getImageUrl(datum, "image1");
        } else {
            datum = this.findPassporMrzBiographicDatum(data);

            if (!!datum) {
                documentFace = CardService.getOnFileImageUrl(datum);
            }
        }


        return documentFace;
    },
    isLivePhotoCaptured(data) {
        let retVal = true;

        let datum = this.findPassportNfcDatum(data);
        if (!datum) {
            datum = this.findPassporMrzBiographicDatum(data);
        }
        let faceDatum = this.findPassportFaceDatum(data);
        if (
            !faceDatum ||
            !datum ||
            datum.modificationTime > faceDatum.modificationTime) {
            retVal = false;
        }

        return retVal;
    },
    isPassportCardDataValid(data) {
        let retVal = false;

        let passportMatch = this.findPassportMatch(data);
        if (!!passportMatch) {
            retVal = !!passportMatch.passportNumber;
        }

        return retVal;
    }
}

export default PassportCardService;
