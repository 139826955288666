import PopupContainer from "./PopupContainer/PopupContainer";
import React, {Fragment, useEffect, useState} from "react";
import CardService from "../../../Service/CardService";
import WebrtcStateService from "../../../Service/WebrtcStateService";
import CardStateService from "../../../Service/CardStateService";
import PassportCard from "./PassportCard/PassportCard";
import PersonCard from "./PersonCard/PersonCard";
import LicensePlateCard from "./LicensePlateCard/LicensePlateCard";
import Configuration from "../../../Configuration";
import RecognizerService from "../../../Service/RecognizerService";


const CardPopup = ({data, index, popup}) => {

    const [snippet, setSnippet] = useState(CardService.resolveCardSnippet(data));

    let card = <Fragment />;

    if (snippet && snippet.data) {
        let isAnyValid = snippet.data.some(datum => !RecognizerService.isNotValid(datum));

        if (isAnyValid) {
            let types = CardStateService.getTypesFromSnippet(snippet);
            if (types.hasPassportType) {
                card = <PassportCard data={snippet.data}/>;
            } else if (types.hasPersonType) {
                card = <PersonCard data={snippet.data}/>
            } else if (types.hasLicensePlateType) {
                card = <LicensePlateCard data={snippet.data}/>;
            }
        }
    }

    let message = "";

    if (WebrtcStateService.isVideoStreamDisconnected()) {
        message = "Video stream disconnected.";
    } else if (WebrtcStateService.isVideoStreamPlaying()) {
        message = "Video stream connected."

    } else if (WebrtcStateService.isVideoStreamPaused()) {
        message = "Video stream paused."
    } else {
        message = "Connecting...";
    }

    useEffect(() => {
        console.debug(`>> CardPopup:: check expiration in ${Configuration.cardPopupExpirationCheckIntervalInMilliseconds}ms intervals`);
        let intervalTimer = setInterval(() => {

            let snippetNullOrExpired = true;
            if (snippet && snippet.timestampInMilliseconds) {
                let currentTimestampInMilliseconds = new Date().getTime();
                snippetNullOrExpired = currentTimestampInMilliseconds > (snippet.timestampInMilliseconds + Configuration.cardPopupHistoryCheckIntervalInMilliseconds)
                console.debug(`>> CardPopup: checking expiration - is snippet expired? ${snippetNullOrExpired} [ current timestamp ${currentTimestampInMilliseconds}  snippet timestamp ${snippet.timestampInMilliseconds}  interval for snippet type ${CardStateService.getSnippetMemoryInterval(snippet.type)}  snippet type ${snippet.type}]`);
            }

            if (snippetNullOrExpired) {
                let latestSnippet = CardService.resolveCardSnippet();
                console.debug(`>> CardPopup: is latest snippet not null? ${latestSnippet != null}`);
                if (latestSnippet && latestSnippet.data) {
                    console.debug(`>> CardPopup: setting latest snippet`);
                    setSnippet(latestSnippet);
                }
            }

        }, Configuration.cardPopupExpirationCheckIntervalInMilliseconds);


        return () => {
            clearInterval(intervalTimer);
        };

    }, [snippet]);

    const popupDataUpdateHandler = (event) => {
        if (!event || !event.detail) {
            return;
        }

        console.debug(`>> CardPopup: popupDataUpdateHandler`);
        let data = event.detail;

        let latestSnippet = CardService.resolveCardSnippet(data);
        console.debug(`>> CardPopup: popupDataUpdateHandler: is latest snippet not null? ${latestSnippet != null}`);

        if (snippet && latestSnippet && latestSnippet.data && latestSnippet.type === snippet.type) {
            console.debug(`>> CardPopup: popupDataUpdateHandler: setting latest snippet`);
            setSnippet(latestSnippet);
        }
    }

    useEffect(() => {
        CardService.addCardPopupDataUpdateEventHandler(popupDataUpdateHandler);
        return () => {
            CardService.removeCardPopupDataUpdateEventHandler(popupDataUpdateHandler);
        }
    })
    return <PopupContainer data-recognizerid={data[0].id} data-recognizertype={data[0].type} data-testid={`Popup-${index}`} {...popup}>
        <div className={"CardPopup-Message"}>
            {message}
        </div>
        {card}
    </PopupContainer>;
};

export default CardPopup;
