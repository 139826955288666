import React, {Fragment, useEffect, useState} from "react";
import AuthenticationService from "../../Service/AuthenticationService";
import "./ChatContainer.css";
import WebrtcStateService from "../../Service/WebrtcStateService";
import ChatBox from "./ChatBox/ChatBox";

const ChatContainer = (props) => {

    const [state, setState] = useState({device : {id: null, name: ""}, messages: {}});

    const [videoStarted, setVideoStarted] = useState(false);

    let snippet = <Fragment/>;

    const TAG = ">> ChatContainer::";

    console.debug(TAG, "State is ", state)
    const handleRecognizerFocusedEvent = (event) => {
        let device = event.detail;

        console.debug(TAG, "Updating device", device)
        if (!!device) {
            setState({
                ...state,
                device: device
            });
        } else {
            console.debug(TAG, "Setting device to null")
            setState({device: null, messages: {}});
        }
    }

    const handleWebrtcReceiveChatMessage = (event) => {

        try {
            console.info(TAG, "REceived WebRTC Chat Message event", event);
            let messagePayload = event.detail;
            let messageContent = messagePayload.mContent;
            let colonIndex = messageContent.indexOf(":");
            let messageUsername = messageContent.substring(0, colonIndex);
            let messageString = messageContent.substring(colonIndex + 1).trim();

            if (!state.messages[state.device.id]) {
                state.messages[state.device.id] = [];
            }
            state.messages[state.device.id] = state.messages[state.device.id].concat({
                author: {
                    username: messageUsername,
                    id: messageUsername
                },
                text: messageString,
                timestamp: +new Date(),
                type: 'text'
            });

            setState({
                device: state.device,
                messages: state.messages
            });

        } catch (error) {
            console.error(error);
        }
    }

    const handleWebRTCVideoEvent = (eventName, event) => {
        if (!videoStarted) {
            setVideoStarted(WebrtcStateService.isVideoStreamPlaying());
        }
    }

    useEffect(() => {
        WebrtcStateService.addRecognizerFocusEventListener(handleRecognizerFocusedEvent);
        WebrtcStateService.addWertcReceiveChatMessageEventListener(handleWebrtcReceiveChatMessage);
        WebrtcStateService.addWebrtcVideoEventListeners(handleWebRTCVideoEvent);

        return () => {
            WebrtcStateService.removeRecognizerFocusEventListener(handleRecognizerFocusedEvent);
            WebrtcStateService.removeWebrtcReceiveChatMessageEventListener(handleWebrtcReceiveChatMessage);
            WebrtcStateService.removeWebrtcVideoEventListeners(handleWebRTCVideoEvent);
        };
    })



    let keycloakState = AuthenticationService.keycloakState;
    let username = "";
    let userId = "";

    if (!!keycloakState) {
        if (keycloakState.authenticated) {
            username = keycloakState.tokenParsed.preferred_username;
            userId = keycloakState.tokenParsed.sub;
        }
    }

    let dataTestIdAttributeValue = "nochatbox";

    // DebugService.showDebugMessage("Container: is video stream playing? " + WebrtcStateService.isVideoStreamPlaying());
    if (!!state.device && keycloakState.authenticated && videoStarted) {

        const handleMessage = (message) => {
            console.debug(TAG, "message:", message);

            let outboundChatMessage = username + ": " + message;
            WebrtcStateService.dispatchWebrtcSendChatMessage(outboundChatMessage);


            if (!state.messages[state.device.id]) {
                state.messages[state.device.id] = [];
            }
            let now = +new Date();
            state.messages[state.device.id] = state.messages[state.device.id].concat({
                key: now,
                author: {
                    username: username,
                    id: userId
                },
                text: message,
                timestamp: now,
                type: 'text'
            })

            setState({
                device: state.device,
                messages: state.messages
            });
        }

        dataTestIdAttributeValue = "chatbox";
        let messageList = state.messages[state.device.id];
        if (!messageList) {
            messageList = [];
        }
        snippet = <ChatBox
            userId={userId}
            messages={messageList}
            onSendMessage={handleMessage}
            activeAuthor={{username: username, id: userId, avatarUrl: null}}

        >

        </ChatBox>;
    }


    return <div
        data-testid={dataTestIdAttributeValue}
        className={"ChatContainer"}

    >
        {snippet}
    </div>;
}

export default ChatContainer;
