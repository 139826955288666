const TYPES = {
    SINGLE_REQUEST: {id: -1, description: "singlerequest"},
    NONE: {id: 0, description: "none"},
    FACE: {id: 1, description: "face"},
    LICENSE_PLATE: {id: 2, description: "licensePlate"},
    PASSPORT: {id: 3, description: "passport"},
    PASSPORT_FACE: {id: 4, description: "passportFace"},
    PASSPORT_MRZ: {id: 5, description: "passportMrz"},
    PASSPORT_MRZ_BIOGRAPHIC: {id: 6, description: "passportMrzBiographic"},
    PASSPORT_NFC_DATA: {id: 7, description: "passportNfcData"}
};

export default TYPES;