import TYPES from "../Component/MapboxMapContainer/Recognizers/Types";
import PassportCardService from "./PassportCardService";
import CardStateService from "./CardStateService";
import Configuration from "../Configuration";


const PASSPORT_TYPES = [TYPES.PASSPORT, TYPES.PASSPORT_FACE, TYPES.PASSPORT_NFC_DATA, TYPES.PASSPORT_MRZ, TYPES.PASSPORT_MRZ_BIOGRAPHIC];

const CardService = {

    addDismissPopupEventHandler: (callback) => {
        document.addEventListener("dismiss-card-popup", callback)
    },

    removeDismissPopupEventHandler: (callback) => {
        document.removeEventListener("dismiss-card-popup", callback)
    },
    dismissCardsExcept(id) {
        let event = new CustomEvent("dismiss-card-popup",
            {
                bubbles: false,
                detail: {excludedId: id}
            });
        window.document.dispatchEvent(event);
    },
    addCardPopupDataUpdateEventHandler: (callback) => {
        document.addEventListener("card-popup-data-update", callback)
    },

    removeCardPopupDataUpdateEventHandler: (callback) => {
        document.removeEventListener("card-popup-data-update", callback)
    },
    dispatchDataUpdate(data) {
        let event = new CustomEvent("card-popup-data-update",
            {
                bubbles: false,
                detail: data
            });
        window.document.dispatchEvent(event);
    },
    findDatum(data, type) {
        let returnValue = null;
        for (let index in data) {
            let datum = data[index];
            if (datum.type !== type) {
                continue;
            }
            returnValue = datum;
            break;
        }

        return returnValue;
    },
    getDisplayString(inputString) {

        if (!inputString) {
            return "";
        }

        return inputString + " ";
    },
    getImageUrlWithTimestamp(datum, imageKey, timestamp) {
        let url = null;
        let parsedImage = JSON.parse(datum.image);
        if (parsedImage && parsedImage[imageKey]) {
            url = parsedImage[imageKey] + "?" + timestamp;
            if (!url.startsWith("http")) {
                url = "/" + url;
            }
        }

        return url;
    },
    getImageUrl(datum, imageKey) {
        return this.getImageUrlWithTimestamp(datum, imageKey, Math.random());
    },
    getOnFileImageUrl(datum) {
        let passportMatch = PassportCardService.getFirstFetchedContentWithProperty(datum, "passportMatch")
        return "data:image/jpg;base64," + passportMatch.imageBase64Data;
    },
    getRecognizerName(data) {
        if (!data || !data.length || !data[0] | !data[0].name) {
            return null;
        }
        return data[0].name;
    },
    isLicenseplateCard(type) {
        let isLicensePlateCard = type === TYPES.LICENSE_PLATE.id;

        return isLicensePlateCard
    },
    isPassportCard(type) {
        let isPassportCard = PASSPORT_TYPES.some((passportType) =>
            type === passportType.id);

        return isPassportCard
    },
    isPersonCard(type) {
        let isPersonCard = type === TYPES.FACE.id;

        return isPersonCard
    },
    isRelatedType(type1, type2) {

        let cardType1 = this.getCardType(type1);
        let cardType2 = this.getCardType(type2);

        return cardType1 === cardType2;
    },
    getCardType(type) {

        let cardType = null;

        if (this.isPassportCard(type)) {
            cardType = TYPES.PASSPORT.id;
        } else if (this.isPersonCard(type)) {
            cardType = TYPES.FACE.id;
        } else if (this.isLicenseplateCard(type)) {
            cardType = TYPES.LICENSE_PLATE.id;
        }

        return cardType;
    },
    hasDerogatory(data) {
        let returnValue = false;
        for (let index in data) {
            let datum = data[index];
            if (datum.derogatory) {
                returnValue = true;
                break;
            }
        }

        return returnValue;
    },
    getExpiration(type) {

        let expiration = {
            amount: Configuration.cardValidityExpirationIntervalAmount,
            unit: Configuration.cardValidityExpirationIntervalUnit
        };

        return expiration;
    },
    getMainTypeFromDatum(datum) {
        let types = CardService.getTypesFromDatum(datum);
        let mainType = TYPES.NONE;
        if (types.hasPassportType) {
            mainType = TYPES.PASSPORT.id;
        } else if (types.hasPersonType) {
            mainType = TYPES.FACE.id;
        } else if (types.hasLicensePlateType) {
            mainType = TYPES.LICENSE_PLATE.id;
        }
        return mainType;
    },
    getTypesFromDatum(datum) {
        let returnValue = {
            hasPassportType: false,
            hasPersonType: false,
            hasLicensePlateType: false
        };

        let fetchedContentCount = datum.fetchedContent.length;
        for (let fetchedContentIndex = 0; fetchedContentIndex < fetchedContentCount; fetchedContentIndex++) {
            let fetchedContent = datum.fetchedContent[fetchedContentIndex];
            let contentType = fetchedContent.type;
            if (CardService.isPassportCard(contentType)) {
                returnValue.hasPassportType = true;
            } else if (CardService.isPersonCard(contentType)) {
                returnValue.hasPersonType = true;
            } else if (CardService.isLicenseplateCard(contentType)) {
                returnValue.hasLicensePlateType = true;
            }
        }
        return returnValue;
    },
    resolveCardSnippet(data) {
        if (!data) {
            data = [];
        }
        console.debug(">>> CardService:: resolveCardSnippet", data);
        let hasPassportType = false;
        let hasLicensePlateType = false;
        let hasPersonType = false;

        // let snippet = <Fragment/>;
        let snippet = {};

        let dataLength = data.length;
        for (let dataIndex = 0; dataIndex < dataLength; dataIndex++) {
            let datum = data[dataIndex];
            let detectedTypes = CardService.getTypesFromDatum(datum);
            hasPassportType = hasPassportType || detectedTypes.hasPassportType;
            hasLicensePlateType = hasLicensePlateType || detectedTypes.hasLicensePlateType;
            hasPersonType = hasPersonType || detectedTypes.hasPersonType;
        }

        console.debug(`>>> CardService:: hasPassportType? ${hasPassportType} ` +
            `hasPersonType? ${hasPersonType}  hasLicensePlateType? ${hasLicensePlateType}`
        );
        if (hasPassportType) {
            let memorySnippet = CardStateService.getLatestSnippet();
            let isFromMemorySnippet = false;

            if (memorySnippet && memorySnippet.type && CardService.isPassportCard(memorySnippet.type)) {

                let memorySnippetPassportDocumentNumber = PassportCardService.getPassportDocumentNumber(memorySnippet.data);
                let dataPassportDocumentNumber = PassportCardService.getPassportDocumentNumber(data);

                if (memorySnippetPassportDocumentNumber === dataPassportDocumentNumber) {
                    let memorySnippetPassportLiveFace = PassportCardService.getPassportLiveFace(memorySnippet.data);
                    let dataPassportLiveFace = PassportCardService.getPassportLiveFace(data);

                    if (memorySnippetPassportLiveFace && !dataPassportLiveFace) {
                        snippet = memorySnippet;
                        isFromMemorySnippet = true;
                    } else {
                        let memorySnippetPassportDocumentMatch = PassportCardService.getPassportDocumentMatch(memorySnippet.data);
                        let dataPassportDocumentMatch = PassportCardService.getPassportDocumentMatch(data);
                        if (memorySnippetPassportDocumentMatch && (!dataPassportDocumentMatch || dataPassportDocumentMatch.trim() === "")) {
                            snippet = memorySnippet;
                            isFromMemorySnippet = true;
                        }
                    }
                }
            }


            console.debug(`>> CardService:: is passport snippet from memory? ${isFromMemorySnippet}`, data)
            if (!isFromMemorySnippet) {
                snippet = CardStateService.putLatestSnippet(TYPES.PASSPORT.id, data);
            }
        } else if (hasLicensePlateType) {
            snippet = CardStateService.putLatestSnippet(TYPES.LICENSE_PLATE.id, data);
        } else if (hasPersonType) {
            snippet = CardStateService.putLatestSnippet(TYPES.FACE.id, data);
        } else {
            snippet = CardStateService.getLatestSnippet();
        }

        return snippet;
    }
};

export default CardService;
