import React from "react";
import LicensePlateCardService from "../../../../Service/LicensePlateCardService";
import CardService from "../../../../Service/CardService";
import "./LicensePlateCard.css";


const LicensePlateCard = (props) => {

    const cleanup = () => {
    }

    const setup = () => {

        return cleanup;
    }

    React.useEffect(setup);

    console.debug("Rendering passport card", props.data);

    let recognizerName = CardService.getRecognizerName(props.data),
        licensePlateLiveImage = LicensePlateCardService.getLicensePlateLiveImage(props.data),
        licensePlateNumber = LicensePlateCardService.getLicensePlateNumber(props.data),
        licensePlateConfidence = LicensePlateCardService.getLicensePlateConfidence(props.data),
        licensePlateVin = LicensePlateCardService.getLicensePlateVin(props.data),
        licensePlateVehicleType = LicensePlateCardService.getLicensePlateVehicleType(props.data),
        licensePlateLastSeenDate = LicensePlateCardService.getLicensePlateLastSeenDate(props.data),
        licensePlateSource = LicensePlateCardService.getLicensePlateSource(props.data),
        licensePlateSourceDate = LicensePlateCardService.getLicensePlateSourceDate(props.data),
        licensePlateDerogatory = LicensePlateCardService.getLicensePlateIsDerogatory(props.data),
        licensePlateDisposition = LicensePlateCardService.getLicensePlateDisposition(props.data),
        licensePlateDispositionCss = licensePlateDerogatory ? "LicensePlateCard-Disposition-Derogatory" : "LicensePlateCard-Disposition";

    let rightColumn = (<React.Fragment>
        <div className={"LicensePlateCard-RightDataContainer"}>
            <div className={"LicensePlateCard-RightDataHeadersContainer"}>
                <div>VIN</div>
                <div>Vehicle Type</div>
                <div>Last Seen</div>
                <div>Source</div>
                <div>Source Date</div>
            </div>
            <div className={"LicensePlateCard-RightDataValuesContainer"}>
                <div>{licensePlateVin}</div>
                <div>{licensePlateVehicleType}</div>
                <div>{licensePlateLastSeenDate}</div>
                <div>{licensePlateSource}</div>
                <div>{licensePlateSourceDate}</div>
            </div>
        </div>
        <div className={licensePlateDispositionCss}>{licensePlateDisposition}</div>
    </React.Fragment>);

    if (!licensePlateSource) {
        rightColumn = (<div className={"LicensePlateCard-RightDataContainer-NoMatch"}>No Match</div>)
    }

    return <div className={"LicensePlateCard-Container"}>
        <div className="LicensePlateCard-Left">
            <div>
                <img alt={"Live license plate"} className={"LicensePlateCard-LiveImage"} src={licensePlateLiveImage}></img>
            </div>
            <div className={"LicensePlateCard-LeftDataContainer"}>
                <div className={"LicensePlateCard-LeftDataHeadersContainer"}>
                    <div>License Plate</div>
                    <div>Confidence</div>
                </div>
                <div className={"LicensePlateCard-LeftDataValuesContainer"}>
                    <div data-testid={"LicensePlateNumber"}>{licensePlateNumber}</div>
                    <div>{licensePlateConfidence}</div>
                </div>

            </div>
            <div className={"LicensePlateCard-RecognizerName"}>
                <div>{recognizerName}</div>
            </div>
        </div>
        <div className="LicensePlateCard-Right">
            {rightColumn}
        </div>
    </div>;
};

export default LicensePlateCard;
